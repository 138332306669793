import React,{useEffect,useState} from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../../actions/actions';
import {pathSpliter} from '../../lib/functions';
import PurchaseVoucher from './components/load_purchase_return_voucher';
import axios from 'axios';
import { APP_URL, API_URL } from "../../config.js";
const PurchaseVoucherQuick = ({location,currentRouteSet,authInfo})=>{
  let [institution,institutionSet] = useState(null);

    useEffect(()=>{
        currentRouteSet(pathSpliter(location.pathname,1));
        getInstitution();

    },[]);

    let getInstitution = async ()=>{
      await  axios.get(`${API_URL}/api/get-institution`,{headers:{'auth-token':authInfo.token}}).then(res=>{
          institutionSet(res.data)
   })
  }

      return(
          <>
              <PurchaseVoucher pur_r_id={parseFloat(pathSpliter(location.pathname,3))} institution={institution} />
          </>
      )
}
  const mapStateToPops = (state)=>{
    return {
      currentRoute:state.currentRouteReducer,
      authInfo:state.authInfoReducer
    }
}

export default connect(mapStateToPops,{currentRouteSet})(PurchaseVoucherQuick);
