import React,{ useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import { APP_URL, API_URL } from "../../config.js";
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';

import DateFnsUtils from '@date-io/date-fns';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {pathSpliter,authInfo,currentDateTime} from '../../lib/functions'

import {currentRouteSet} from '../../actions/actions';


const UnitManage = ({location,currentRouteSet,currentRoute,authInfo})=>{
    const classes = useStyles();
 
  useEffect(()=>{
    getItems()
    getGroups();
    getCategories();
    getUnits();
    getCode();
    getModels()
    getOrigins()
    // getTaxAccs();
    currentRouteSet(pathSpliter(location.pathname,1));
    
  },[])



    let getTaxAccs = ()=>{
      axios.post(`${API_URL}/api/get-accounts-by-type`,{acc_type_id:'dutie_&_tax'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        taxAccsSet(res.data)
      })
    }

    // Init states start
 
    let [items,itemsSet] = useState([]);
    let [groups,groupsSet] = useState([]);
    let [models,modelsSet] = useState([]);
    let [origins,originsSet] = useState([]);
    let [categories,categoriesSet] = useState([]);
    let [taxAccs,taxAccsSet] = useState([]);
    let [units,unitsSet] = useState([]);
    let [selectedGroup,selectedGroupSet] = useState(null);
    let [selectedCategory,selectedCategorySet] = useState(null);
    let [selectedModel,selectedModelSet] = useState(null);
    let [selectedOrigin,selectedOriginSet] = useState(null);
    let [selectedUnit,selectedUnitSet] = useState(null);
    let [selectedTaxAcc,selectedTaxAccSet] = useState(null);
    let [photo,photo_set] = useState('')
    let [prev_photo,prev_photo_set] = useState('')


    const [selectedDate, selectedDateSet] = useState(currentDateTime);


   
    const handleDateChange = (date) => {
        selectedDateSet(date);
      };


    
    let [loadingList,loadingListSet] = useState(false)
    let [loadingSave,loadingSaveSet] = useState(false)

    let [action,action_set] = useState('create');
    let [item_id,item_id_set] = useState(0);
    let [item_code,item_code_set] = useState('');
    let [item_barcode,item_barcode_set] = useState('');
    let [item_name,item_name_set] = useState('');


    let [narration,narration_set] = useState('');
    let [init_qty,init_qty_set] = useState(0);
    let [init_rate,init_rate_set] = useState(0);
    let [init_value,init_value_set] = useState(0);
    let [min_sale_qty,min_sale_qty_set] = useState(0);
    let [min_sale_rate,min_sale_rate_set] = useState(0);
    let [purchase_rate,purchase_rate_set] = useState(0);
    let [sale_rate,sale_rate_set] = useState(0);
    let [wholesaler_rate,wholesaler_rate_set] = useState(0);
    let [retailer_rate,retailer_rate_set] = useState(0);
    let [corporate_rate,corporate_rate_set] = useState(0);
    let [distributor_rate,distributor_rate_set] = useState(0);
    let [discount_per,discount_per_set] = useState(0);
    let [tax_per,tax_per_set] = useState(0);
    
    let [is_serial,is_serial_set] = useState('no');
    let [is_service,is_service_set] = useState('no');

    let item_code_ref = useRef(null);
    let item_name_ref = useRef(null);
    let item_barcode_ref = useRef(null);
    let group_ref = useRef(null);
    let category_ref = useRef(null);
    let unit_ref = useRef(null);
    let narration_ref = useRef(null);
    let init_qty_ref = useRef(null);
    let init_rate_ref = useRef(null);
    let init_value_ref = useRef(null);
    let init_date_ref = useRef(null);
    let min_qty_ref = useRef(null);
    let min_rate_ref = useRef(null);
    let min_pur_rate_ref = useRef(null);
    let sale_rate_ref = useRef(null);
    let wholesale_rate_ref = useRef(null);
    let retailer_rate_ref = useRef(null);
    let corporate_rate_ref = useRef(null);
    let distributor_rate_ref = useRef(null);
    let discount_ref = useRef(null);
    let tax_per_ref = useRef(null);
    let tax_acc_ref = useRef(null);
    let model_ref = useRef(null);
    let origin_ref = useRef(null);



    let objectToFormData = (obj)=> {
      const formData = new FormData();
    
      Object.entries(obj).forEach(([key, value]) => {
        formData.append(key, value);
      });
    
      return formData;
    }



    const photoAction = (e)=>{
      let photoInput = document.getElementById('photo')
      let target = document.getElementById('target')
      photo_set(e.target.files[0])

      var fr = new FileReader();

      fr.onload = function(){
        target.src = fr.result;
     }
    fr.readAsDataURL(photoInput.files[0]);
     

    }



    // Init states end
    // Methods script start
    const saveFormAction = async ()=>{
          if(item_name.trim() == ''){
            swal({
              title:'Item Name is Required.',
              icon:'warning'
            })
            return false
          }if(selectedUnit == null){
            swal({
              title:'Select a Unit',
              icon:'warning'
            })
            return false
          }else{


            let obj = {
              item_name:item_name.trim(),
              item_code:item_code.trim(),
              item_barcode:item_barcode.trim(),
              init_date: selectedDate,
              is_serial,
              is_service,
              group_id : selectedGroup != null ? selectedGroup.group_id : 0,
              category_id : selectedCategory != null ? selectedCategory.category_id : 0,
              model_id : selectedModel != null ? selectedModel.model_id : 0,
              origin_id : selectedOrigin != null ? selectedOrigin.origin_id : 0,
              unit_id : selectedUnit.unit_id,
              init_qty : init_qty == ''? 0 : init_qty,
              init_rate : init_rate == ''? 0 : init_rate,
              init_value : init_value == ''? 0 : init_value,
              min_sale_qty : min_sale_qty == ''? 0 : min_sale_qty,
              min_sale_rate : min_sale_rate == ''? 0 : min_sale_rate,
              purchase_rate : purchase_rate == ''? 0 : purchase_rate,
              sale_rate : sale_rate == ''? 0 : sale_rate,
              wholesaler_rate : wholesaler_rate == ''? 0 : wholesaler_rate,
              retailer_rate : retailer_rate == ''? 0 : retailer_rate,
              corporate_rate : corporate_rate == ''? 0 : corporate_rate,
              distributor_rate : distributor_rate == ''? 0 : distributor_rate,
              discount_per : discount_per == ''? 0 : discount_per,
              tax_per : tax_per == ''? 0 : tax_per,
              // tax_acc_id : selectedTaxAcc != null? selectedTaxAcc.acc_id : 0,
              narration,
              action,
              item_id,

              prev_photo,
              photo
            }

            const formData = objectToFormData(obj);


            loadingSaveSet(true);
            await axios.post(`${API_URL}/api/save-item`,formData,{headers:{'Content-Type': 'multipart/form-data','auth-token': authInfo.token}}).then(res=>{
              loadingSaveSet(false);
              if(res.data.error){
                swal({
                  title: res.data.msg,
                  icon:'warning'
                })
              }else{
                
                swal({
                  title: res.data.msg,
                  icon:'success'
                })
                getCode()
                getItems()

                selectedDateSet(currentDateTime)


                item_id_set(0)
                item_name_set('')
                is_serial_set('no')
          
                item_barcode_set('')
                is_service_set('no')
                selectedGroupSet(null)
                selectedCategorySet(null)
                selectedUnitSet(null)
                selectedOriginSet(null)
                selectedModelSet(null)

                selectedTaxAccSet(null)
                
                init_qty_set(0)
                init_rate_set(0)
                init_value_set(0)
                min_sale_qty_set(0)
                min_sale_rate_set(0)
                purchase_rate_set(0)
                sale_rate_set(0)
                wholesaler_rate_set(0)
                retailer_rate_set(0)
                corporate_rate_set(0)
                distributor_rate_set(0)
                discount_per_set(0)
                tax_per_set(0)
       
                narration_set('')




                let photoInput = document.getElementById('photo')
                photoInput.value = null
                let img = document.getElementById('target')
                img.src  = ''
                prev_photo_set('')
                photo_set('')

                
                action_set('create')


              }
              
            });
          }
          
    }

    const getItems = async ()=>{
        await axios.post(`${API_URL}/api/get-items`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
            itemsSet(res.data)
        })
      }

    const getCode = async ()=>{
        await axios.post(`${API_URL}/api/get-item-code`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
          item_code_set(res.data)
        })
      }
    const getGroups = async ()=>{
        await axios.post(`${API_URL}/api/get-item-groups`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
          groupsSet(res.data)
        })
      }


      const getModels = async ()=>{
        await axios.post(`${API_URL}/api/get-item-models`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
          modelsSet(res.data)
        })
      }

      const getOrigins = async ()=>{
        await axios.post(`${API_URL}/api/get-item-origins`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
          originsSet(res.data)
        })
      }

      const getCategories = async ()=>{
        await axios.post(`${API_URL}/api/get-item-categories`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
          categoriesSet(res.data)
        })
      }

  
    const getUnits = async ()=>{
      await axios.post(`${API_URL}/api/get-item-units`,{'type':'all'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        unitsSet(res.data)
      })
    }


   
   


    // Methods script end
    const ActionOptions = (props)=>{
      return(<div style={{textAlign:'right'}}> 

{
  props.rowData[1] != '' && props.rowData[1] != null?<a href={API_URL+'/'+props.rowData[1]} target='_blank'> <img style={{width:'70px',height: '40px'}}  alt="" 
  src={API_URL+'/'+props.rowData[1]}   
  /> </a>:''
}



{
    authInfo.role !='user'?(
    <>
    <Link to={{pathname:`/settings/item-barcode/${props.rowData[0]}`}}> 
<HorizontalSplitIcon style={{cursor:'pointer',color: '#222',fontSize: '28px'}}/>

   </Link>

        <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>itemEdit(props.rowData)}/>
      
        <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>itemDelete(props.rowData)}/>
            
   </>):''
 }
      
      </div>)
    
    }


    const itemEdit = (row,index)=>{
      let item =  items.filter((item)=>item.item_id == row[0])

      item_id_set(item[0].item_id)
      item_name_set(item[0].item_name)
      is_serial_set(item[0].is_serial)
      item_code_set(item[0].item_code)
      item_barcode_set(item[0].item_barcode)
      selectedDateSet(item[0].init_date)
      is_service_set(item[0].is_service)
      selectedGroupSet({group_id: item[0].group_id,group_name:item[0].group_name})
      selectedCategorySet({category_id: item[0].category_id,category_name:item[0].category_name})
      selectedOriginSet({origin_id: item[0].origin_id,origin_name:item[0].origin_name})
      selectedModelSet({model_id: item[0].model_id,model_name:item[0].model_name})
      selectedUnitSet({unit_id: item[0].unit_id,unit_name:item[0].unit_name})
      selectedTaxAccSet({acc_id: item[0].acc_id,acc_name:item[0].acc_name})
      
      init_qty_set(item[0].init_qty)
      init_rate_set(item[0].init_rate)
      init_value_set(item[0].init_value)
      min_sale_qty_set(item[0].min_sale_qty)
      min_sale_rate_set(item[0].min_sale_rate)
      purchase_rate_set(item[0].purchase_rate)
      sale_rate_set(item[0].sale_rate)
      wholesaler_rate_set(item[0].wholesaler_rate)
      retailer_rate_set(item[0].retailer_rate)
      corporate_rate_set(item[0].corporate_rate)
      distributor_rate_set(item[0].distributor_rate)
      discount_per_set(item[0].discount_per)
      tax_per_set(item[0].tax_per)
 
      narration_set(item[0].narration)
      
      action_set('update')



      let img = document.getElementById('target')

      img.src  = `${API_URL}/${item[0].photo}`




      prev_photo_set(item[0].photo)

     
      

    }


    const itemDelete = async (row)=>{
      swal({
        title:'Are you sure delete this?',
        icon:'warning',
        buttons:true
      }).then(async(yes)=>{
        if(yes){
          let item =  items.filter((item)=>item.item_id == row[0])
          await axios.post(`${API_URL}/api/delete-item`,{item_id:item[0].item_id,photo:row[1]},
          {headers:{'auth-token':authInfo.token}}).then(res=>{
                  getItems();
                  swal({
                    title: res.data.msg,
                    icon:'success'
                  })
                  
                })
        }else{
          return false
        }
      })
      

          }
    
    const columns = [
      {name: "item_id",options: { display: 'excluded' }},
      {name: "photo",options: { display: 'excluded' }},
      {name:"SL",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
        return ( <>{parseFloat(tableMeta.rowIndex)+1}</> ); 
      }
      },headerStyle: {
        textAlign:'left'
      }},
      {name: "item_code",label: " Code",options: {filter: true,sort: true}},
      {name: "item_name",label: "Item Name",options: {filter: true,sort: true}},
      // {name: "item_barcode",label: "Barcode",options: {filter: true,sort: true}},
      {name: "group_name",label: "Group",options: {filter: true,sort: true}},
      {name: "category_name",label: "Category",options: {filter: true,sort: true}},
      // {name: "model_name",label: "Model",options: {filter: true,sort: true}},
      // {name: "origin_name",label: "Origin",options: {filter: true,sort: true}},
      {name: "unit_name",label: "Unit of Measure",options: {filter: true,sort: true}},
      {name: "unit_symbol",label: "Master Unit",options: {filter: true,sort: true}},
      {name: "base_unit_name",label: "Base Unit",options: {filter: true,sort: true}},
      {name: "sale_rate",label: "Retail Rate",options: {filter: true,sort: true}},
       {name: "wholesaler_rate",label: "WholeSale Rate",options: {filter: true,sort: true}},
       {name: "purchase_rate",label: "Purchase Rate",options: {filter: true,sort: true}},
      {name: "is_serial",label: "Is Serial",options: {filter: true,sort: true}},
      // {name: "discount_per",label: "Discount %",options: {filter: true,sort: true}},
      // {name: "tax_per",label: "Vat %",options: {filter: true,sort: true}},
      // {name: "acc_name",label: "Vat Acc",options: {filter: true,sort: true}},
      
      {name:"photo & actions",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
          return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
             /> ); 
      }
      },headerStyle: {
        textAlign:'right'
      }}
     ];
            
     const options = {
       filterType: 'checkbox',
       selectableRows: 'none',
       display: "excluded"
      }




    return (
    <div className={classes.root}>

     <Paper className={classes.paper} style={{marginTop:'-15px'}}>
    <h2 className={classes.pageEntryLabel}>Item  Entry </h2>
      <Grid container spacing={2}>
        
      <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
        label="Item Code" name="item_code" value={item_code} 
        inputRef={item_code_ref}
        onKeyDown={event => {
          if (event.key === "Enter") {
            item_name_ref.current.focus()
          }
        }}
        variant="outlined" size="small" onChange={(e)=>item_code_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField  inputRef={item_name_ref}
        onKeyDown={event => {
          if (event.key === "Enter") {
            item_barcode_ref.current.focus()
          }
        }} autoComplete='off' className={classes.fullWidth} 
        label="Item Name" name="item_name" value={item_name}  variant="outlined" size="small" onChange={(e)=>item_name_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' 
        inputRef={item_barcode_ref}
        onKeyDown={event => {
          if (event.key === "Enter") {
            group_ref.current.focus()
          }
        }}
        className={classes.fullWidth} 
        label="Item Barcode" name="item_barcode" value={item_barcode}  variant="outlined" size="small" onChange={(e)=>item_barcode_set(e.target.value)} />
        </Grid>


        <Grid item xs={12} sm={3} className={classes.plusLinkDiv}> 
        <Link to="/settings/group-manage" className={classes.plusLink} style={{cursor:'pointer'}}  >+</Link>  

              <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={groups} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.group_name}
              value={selectedGroup}
              onChange={(event,selectedObj)=>{
                  selectedGroupSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                inputRef={group_ref}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    category_ref.current.focus()
                  }
                }}
                  {...params}
                  label="Choose a  Group"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>
        
        <Grid item xs={12} sm={3} className={classes.plusLinkDiv}> 
        <Link to="/settings/category-manage" className={classes.plusLink} style={{cursor:'pointer'}}  >+</Link>  

              <Autocomplete
              autoHighlight={false}
              openOnFocus={false}
              style={{ width: '100%' }}
              options={categories} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.category_name}
              value={selectedCategory}
              onChange={(event,selectedObj)=>{
                  selectedCategorySet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                inputRef={category_ref}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    model_ref.current.focus()
                  }
                }}
                  {...params}
                  label="Choose a  Category"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>


        <Grid item xs={12} sm={3} className={classes.plusLinkDiv}> 
        <Link to="/settings/model-manage" className={classes.plusLink} style={{cursor:'pointer'}}  >+</Link>  

              <Autocomplete
              autoHighlight={false}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={models} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.model_name}
              value={selectedModel}
              onChange={(event,selectedObj)=>{
                selectedModelSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                inputRef={model_ref}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    origin_ref.current.focus()
                  }
                }}
                  {...params}
                  label="Choose a  Model"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>


        <Grid item xs={12} sm={3} className={classes.plusLinkDiv}> 
        <Link to="/settings/origin-manage" className={classes.plusLink} style={{cursor:'pointer'}}  >+</Link>  

              <Autocomplete
              autoHighlight={false}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={origins} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.origin_name}
              value={selectedOrigin}
              onChange={(event,selectedObj)=>{
                selectedOriginSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                inputRef={origin_ref}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    unit_ref.current.focus()
                  }
                }}
                  {...params}
                  label="Choose a  Origin"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>

        <Grid item xs={12} sm={3} className={classes.plusLinkDiv}> 
        <Link to="/settings/unit-manage" className={classes.plusLink} style={{cursor:'pointer'}}  >+</Link>  

              <Autocomplete
              autoHighlight={false}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={units} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.unit_name}
              value={selectedUnit}
              onChange={(event,selectedObj)=>{
                  selectedUnitSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                inputRef={unit_ref}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    narration_ref.current.focus()
                  }
                }}
                  {...params}
                  label="Choose a  Unit"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
              <input type="checkbox" 
               id="is_service" checked={is_service=='yes'?true:false} name="is_service" 
                onChange={(e)=>is_service_set(e.target.checked?'yes':'no')}
              />
              <label for="is_service">Is Service Item ?</label>
        </Grid>

        <Grid item xs={12} sm={2}>
              <input type="checkbox" 
              disabled={action=='update'?true:false}
               id="is_serial" checked={is_serial=='yes'?true:false} name="is_serial" 
                onChange={(e)=>is_serial_set(e.target.checked?'yes':'no')}
              />
              <label for="is_serial">Is Serial Item ?</label>
        </Grid>


        <Grid item xs={12} sm={4}> 
        <TextField  autoComplete='off' 
         inputRef={narration_ref}
         onKeyDown={event => {
           if (event.key === "Enter") {
            //  pur_.current.focus()
           }
         }}
          className={classes.fullWidth} 
        label="Narration" name="narration" 
         value={narration}  variant="outlined" size="small" onChange={(e)=>narration_set(e.target.value)} />
        </Grid>

        {/* <Grid item xs={12} sm={3}> 
        <TextField  type="number" 
         inputRef={init_qty_ref}
         onKeyDown={event => {
           if (event.key === "Enter") {
            init_rate_ref.current.focus()
           }
         }}
          autoComplete='off' className={classes.fullWidth} 
        label="Initial Quantity" name="init_qty" value={init_qty}  variant="outlined" size="small" onChange={(e)=>init_qty_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField type="number" autoComplete='off' 
        
        inputRef={init_rate_ref}
        onKeyDown={event => {
          if (event.key === "Enter") {
            init_value_ref.current.focus()
          }
        }}
        
        className={classes.fullWidth} 
        label="Initial Rate" name="init_rate" value={init_rate}  variant="outlined" size="small" onChange={(e)=>init_rate_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField type="number" autoComplete='off' className={classes.fullWidth} 
        inputRef={init_value_ref}
        onKeyDown={event => {
          if (event.key === "Enter") {
            init_date_ref.current.focus()
          }
        }}
        label="Initial Value" name="init_value" value={init_value}  variant="outlined" size="small" onChange={(e)=>init_value_set(e.target.value)} />
        </Grid> */}

{/* 
        <Grid item xs={12} sm={3}> 
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
       
        <KeyboardDatePicker

        inputRef={init_date_ref}
        onKeyDown={event => {
          if (event.key === "Enter") {
            min_qty_ref.current.focus()
          }
        }}

          margin="normal"
          label="Initial Qty of Date"
          format="MM/dd/yyyy"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        
    
    </MuiPickersUtilsProvider>
        </Grid> */}


        



{/* 
        <Grid item xs={12} sm={3}> 
        <TextField type="number" autoComplete='off' 
        
        inputRef={min_qty_ref}
        onKeyDown={event => {
          if (event.key === "Enter") {
            min_rate_ref.current.focus()
          }
        }}

        className={classes.fullWidth} 
        label="Minimum Sale Qty" name="min_sale_qty" value={min_sale_qty}  variant="outlined" size="small" onChange={(e)=>min_sale_qty_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField type="number" 
        inputRef={min_rate_ref}
        onKeyDown={event => {
          if (event.key === "Enter") {
            sale_rate_ref.current.focus()
          }
        }}
         autoComplete='off' className={classes.fullWidth} 
        label="Minimum Sale Rate" name="min_sale_rate" value={min_sale_rate}  variant="outlined" size="small" onChange={(e)=>min_sale_rate_set(e.target.value)} />
        </Grid> */}

        <Grid item xs={12} sm={3}> 
        <TextField  type="number"  
        
         
         autoComplete='off' className={classes.fullWidth} 
        label="Purchase Rate" name="purchase_rate" value={purchase_rate}  variant="outlined" size="small" onChange={(e)=>purchase_rate_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField type="number" 
        inputRef={sale_rate_ref}
        onKeyDown={event => {
          if (event.key === "Enter") {
            wholesale_rate_ref.current.focus()
          }
        }}
         autoComplete='off' className={classes.fullWidth} 
        label="Retail Rate" name="sale_rate" value={sale_rate}  variant="outlined" size="small" onChange={(e)=>sale_rate_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField type="number"
        inputRef={wholesale_rate_ref}
        onKeyDown={event => {
          if (event.key === "Enter") {
            corporate_rate_ref.current.focus()
          }
        }}
         autoComplete='off' className={classes.fullWidth} 
        label="Wholesaler Rate" name="wholesaler_rate" value={wholesaler_rate}  variant="outlined" size="small" onChange={(e)=>wholesaler_rate_set(e.target.value)} />
        </Grid>

        {/* <Grid item xs={12} sm={3}> 
        <TextField type="number" autoComplete='off' className={classes.fullWidth} 
        label="Retailer Rate" 
        inputRef={retailer_rate_ref}
        onKeyDown={event => {
          if (event.key === "Enter") {
            corporate_rate_ref.current.focus()
          }
        }}
         name="retailer_rate" value={retailer_rate}  variant="outlined" size="small" onChange={(e)=>retailer_rate_set(e.target.value)} />
        </Grid> */}

        <Grid item xs={12} sm={3}> 
        <TextField type="number" autoComplete='off' className={classes.fullWidth} 
        label="Corporate Rate" 
        inputRef={corporate_rate_ref}
        onKeyDown={event => {
          if (event.key === "Enter") {
            distributor_rate_ref.current.focus()
          }
        }}
         name="corporate_rate" value={corporate_rate}  variant="outlined" size="small" onChange={(e)=>corporate_rate_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField type="number" 
        
        inputRef={distributor_rate_ref}
        onKeyDown={event => {
          if (event.key === "Enter") {
           saveFormAction()
          }
        }}
        
        autoComplete='off' className={classes.fullWidth} 
        label="Distributor Rate" name="distributor_rate" value={distributor_rate}  variant="outlined" size="small" onChange={(e)=>distributor_rate_set(e.target.value)} />
        </Grid>

        {/* <Grid item xs={12} sm={3}> 
        <TextField type="number" 
        
        inputRef={discount_ref}
        onKeyDown={event => {
          if (event.key === "Enter") {
            tax_per_ref.current.focus()
          }
        }}
        
        autoComplete='off' className={classes.fullWidth} 
        label="Discount %" name="discount_per" value={discount_per}  variant="outlined" size="small" onChange={(e)=>discount_per_set(e.target.value)} />
        </Grid> */}


        {/* <Grid item xs={12} sm={3}> 
        <TextField type="number" 
        
        inputRef={tax_per_ref}
        onKeyDown={event => {
          if (event.key === "Enter") {
            tax_acc_ref.current.focus()
          }
        }}
        
        autoComplete='off' className={classes.fullWidth} 
        label="Vat %" name="tax_per" value={tax_per}  variant="outlined" size="small" onChange={(e)=>tax_per_set(e.target.value)} />
        </Grid> */}

       
        <Grid item xs={12} sm={3} className={classes.plusLinkDiv} style={{display:'none'}}> 
        <Link to="/accounts/account-manage" className={classes.plusLink} style={{cursor:'pointer'}}  >+</Link>  

              <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={taxAccs} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.acc_name}
              value={selectedTaxAcc}
              onChange={(event,selectedObj)=>{
                  selectedTaxAccSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                inputRef={tax_acc_ref}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    narration_ref.current.focus()
                  }
                }}
                  {...params}
                  label="Choose Vat account"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>

  </Grid>




<Grid container>
<Grid item xs={12} sm={2} style={{marginTop: '21px'}} > 
  <p style={{margin:'0px',padding:'0px',textAlign:'left',float:'left'}}>Product Photo</p>
                <input type="file" id="photo" style={{float:'left'}} onChange={(e)=>photoAction(e)} />
            </Grid>
 <Grid item xs={12} sm={1}  > 
 <img style={{width:' 100%',height: '80px',marginTop: '17px',width: '80px',height: '80px',float:''}} id="target" alt="No Photo "  />

            </Grid>

            <Grid item xs={5}>
  <Button style={{marginTop: '25px'}}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        disabled={loadingSave}
        startIcon={<SaveIcon />}
        onClick={saveFormAction}
      >
        Save
      </Button>
  </Grid>     
</Grid>







  

  
          


     </Paper>

     {
      loadingList==true?(<b>Loading...</b>):(
        <Paper className={classes.paper} style={{marginTop:'20px'}}>
        <MUIDataTable
      title={"Item Entry List"}
      data={items}
      columns={columns}
      options={options}
      adjustForCheckbox={false} 
      displaySelectAll={false}
      />
      </Paper>
      )
      
     }

     
    </div>
        
    );
}


 const options = {
   filterType: 'checkbox',
 };
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },

root: {},
'& .MuiTextField-root': {
  margin: theme.spacing(1),
  width: '25ch',
 },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  },
  pageEntryLabel:{
      color: '#7754cc',
      margin: '0px',
      padding: '0px',
      marginTop: '-11px',
      textAlign: 'left',
      marginLeft: '0px',
      marginBottom: '5px'
  },
  fullWidth:{
      width:'100%'
  },
  option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    plusLinkDiv:{
        position:'relative'  
  },
  plusLink:{
    margin: 0,
    padding: 0,
    marginTop: '-21px',
    fontSize: '29px',
    height: '21px',
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    color: '#3e8d54'
  }

}));

const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(UnitManage);