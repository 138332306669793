import React,{useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../../actions/actions';
import {pathSpliter} from '../../lib/functions';
import SalesVoucher from './components/load_sales_voucher';
import {API_URL} from '../../config.js';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

let ViewSalesVoucher = ({location,currentRouteSet,authInfo})=>{
  
  let [vouchers,vouchers_set] = useState([]);
  let [selectedVoucher,selectedVoucherSet] = useState(null);
  let [sale_id,sale_id_set] = useState(null);
  let [institution,institutionSet] = useState(null);
  let [voucher_no,voucher_no_set] = useState('');
  let [loadingVouchers,loadingVouchersSet] = useState(false);
  
  useEffect(()=>{
    currentRouteSet(pathSpliter(location.pathname,1));
    getInstitution();
},[]);

useEffect(()=>{
  getVouchers();
},[voucher_no])

let getVouchers = async ()=>{
  loadingVouchersSet(true)
    await axios.post(`${API_URL}/api/sales-vouchers`,{query:voucher_no.trim()},{headers:{'auth-token':authInfo.token}}).then(res=>{
      loadingVouchersSet(false)
      vouchers_set(res.data)
    })
}

let getInstitution = async ()=>{
    await  axios.get(`${API_URL}/api/get-institution`,{headers:{'auth-token':authInfo.token}}).then(res=>{
        institutionSet(res.data)
 })
}

  return(
          
    <>
        <Grid container>

            <Grid item xs={12} sm={12}>
                  <Grid xs={12} sm={12} style={{width:'270px',marginBottom:'20px',margin: '0px auto'}}>
                  <Autocomplete  
                autoHighlight
                size="small"
              openOnFocus={true}
              style={{width:'100%',height:'20px'}}
              options={vouchers}
              loading={loadingVouchers}
              value={selectedVoucher}
              getOptionLabel={(option) => option.display_text}
              onChange={(event,selectedObj)=>{
                  selectedVoucherSet(selectedObj) 
                  sale_id_set(selectedObj != null ? selectedObj.sale_id : null)
              }}
              renderInput={(params) => <TextField 
                label="SEARCH VOUCHER NO" 
                onChange={e => voucher_no_set(e.target.value)} 
                {...params} 
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingVouchers ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                variant="outlined"
                />} 
                 
          />


    </Grid>
            </Grid>
            </Grid>
        
        {
             
              <SalesVoucher sale_id={sale_id} institution={institution}/>
            
        }
            
      
    </>
)
 
}


  const mapStateToPops = (state)=>{
    return {
      currentRoute:state.currentRouteReducer,
      authInfo:state.authInfoReducer
    }
}

export default connect(mapStateToPops,{currentRouteSet})(ViewSalesVoucher);
