import React,{ useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import { APP_URL, API_URL } from "../../config.js";
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import CircularProgress from '@material-ui/core/CircularProgress';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {pathSpliter,authInfo} from '../../lib/functions'

import {currentRouteSet} from '../../actions/actions';


const GroupManage = ({location,currentRouteSet,currentRoute,authInfo})=>{
    const classes = useStyles();
 
  useEffect(()=>{
    getGroups();
    currentRouteSet(pathSpliter(location.pathname,1));

    getEmployees()
    
  },[])

    // Init states start
 
    let [groups,groupsSet] = useState([]);
    let [employees,employeesSet] = useState([]);
    let [selectedEmployees,selectedEmployeesSet] = useState([]);
    let [selectedEmployee,selectedEmployeeSet] = useState(null);
    let [accounts,accountsSet] = useState([]);

    let [loadingAccounts,loadingAccountsSet] = useState(false);
    let [account_name,account_name_set] = useState('');
    let [components,componentsSet] = useState([
        {component_name:'everyday'},
        {component_name:'weekly'},
        {component_name:'monthly'},
    ]);
    let [selectedComponent,selectedComponentSet] = useState(null);


    let [days,daysSet] = useState([
        {day:'saturday'},
        {day:'sunday'},
        {day:'monday'},
        {day:'tuesday'},
        {day:'wednesday'},
        {day:'thursday'},
        {day:'friday'},
    ]);
    let [selectedDay,selectedDaySet] = useState(null);
    
    
    let [loadingList,loadingListSet] = useState(false)
    let [loadingSave,loadingSaveSet] = useState(false)
    let [selectedAccount,selectedAccountSet] = useState(null)

    let [action,action_set] = useState('create');
    let [group_id,group_id_set] = useState(0);
    let [group_name,group_name_set] = useState('');
    let [metting_place,metting_place_set] = useState('');
    
    
    const group_name_ref = useRef(null)
    const employees_ref = useRef(null)
    const component_ref = useRef(null)
    const metting_day_ref = useRef(null)
    const metting_place_ref = useRef(null)
    
    useEffect(()=>{

   


        loadingAccountsSet(true)
        axios.post(`${API_URL}/api/get-accounts-by-search`,{query:account_name.trim(),multiType:["cash_in_hand","bank_account"]},{headers:{'auth-token':authInfo.token}}).then(res=>{
          accountsSet(res.data)
          loadingAccountsSet(false)
        })
        
  },[account_name])

    // Init states end
    // Methods script start
    const saveFormAction = async ()=>{
          if(group_name.trim() == ''){
            swal({
              title:'Group Name is Required.',
              icon:'warning'
            })
          }else if(selectedComponent == null){
            swal({
              title:'Select a Component.',
              icon:'warning'
            })
          }else if(selectedEmployee == null){
            swal({
              title:'Select a Employee.',
              icon:'warning'
            })
          }else if(selectedAccount == null){
            swal({
              title:'Select a Payment Account.',
              icon:'warning'
            })
          }else{
            loadingSaveSet(true);
            await axios.post(`${API_URL}/api/save-collection-group`,{
              group_name:group_name.trim(),
              employees:selectedEmployees,
              employee_id:selectedEmployee != null ? selectedEmployee.employee_id : 0,
              component_name:selectedComponent != null ? selectedComponent.component_name : '',
              metting_day:selectedDay != null ? selectedDay.day : '',
              acc_id:selectedAccount != null ? selectedAccount.acc_id : 0,
              metting_place:metting_place,
              action,
              group_id
            },{headers:{'auth-token': authInfo.token}}).then(res=>{
              loadingSaveSet(false);
              getGroups()
              if(res.data.error){
                swal({
                  title: res.data.msg,
                  icon:'warning'
                })
              }else{
                swal({
                  title: res.data.msg,
                  icon:'success'
                })

                group_id_set(0)
                group_name_set('')
                selectedEmployeesSet([])
                selectedEmployeeSet(null)
                selectedComponentSet(null)
                selectedAccountSet(null)
                selectedDaySet(null)
                metting_place_set('')
                action_set('create')


              }
              
            });
          }
          
    }


  
    let getEmployees = async ()=>{
        await  axios.post(`${API_URL}/api/get-employees`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
            employeesSet(res.data) 
          })
        }

  
    const getGroups = async ()=>{
      await axios.post(`${API_URL}/api/get-collection-groups`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        groupsSet(res.data)
      })
    }

   


    // Methods script end
    const ActionOptions = (props)=>{
      return(<div style={{textAlign:'right'}}> 

{
    authInfo.role !='user'?(
    <>
        <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>groupEdit(props.rowData)}/>
      
        <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>groupDelete(props.rowData)}/>
            
   </>):''
 }
      
      </div>)
    
    }


    const groupEdit = (row,index)=>{
      let Group =  groups.filter((Group)=>Group.group_id == row[0])
      group_id_set(Group[0].group_id)
      group_name_set(Group[0].group_name)
      selectedComponentSet({component_name: Group[0].component_name})
      selectedDaySet({day: Group[0].metting_day})
      metting_place_set(Group[0].metting_place)
      // selectedEmployeesSet(Group[0].employees)
      selectedEmployeeSet({employee_id:Group[0].employee_id,employee_name: Group[0].employee_name})
      selectedAccountSet({acc_id:Group[0].acc_id,acc_name: Group[0].acc_name})
      action_set('update')
    }


    const groupDelete = async (row)=>{
      swal({
        title:'Are you sure delete this?',
        icon:'warning',
        buttons:true
      }).then(async(yes)=>{
        if(yes){
          let group =  groups.filter((group)=>group.group_id == row[0])
          await axios.post(`${API_URL}/api/delete-collection-group`,{group_id:group[0].group_id},
          {headers:{'auth-token':authInfo.token}}).then(res=>{
                  getGroups();
                  swal({
                    title: res.data.msg,
                    icon:'success'
                  })
                  
                })
        }else{
          return false
        }
      })
      

          }
   
    const columns = [
      {name: "group_id",options: { display: 'excluded' }},
      {name:"SL",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
        return ( <p>{parseFloat(tableMeta.rowIndex)+1}</p> ); 
      }
      },headerStyle: {
        textAlign:'left'
      }},
      {name: "group_name",label: "Group Name",options: {filter: true,sort: true}},
      {name: "employee_name",label: "Employee Name",options: {filter: true,sort: true}},
      {name: "component_name",label: "Component Name",options: {filter: true,sort: true}},
      {name: "metting_day",label: "Metting Day",options: {filter: true,sort: true}},
      {name: "metting_place",label: "Metting Place",options: {filter: true,sort: true}},
      {name: "acc_name",label: "Account Name",options: {filter: true,sort: true}},
      
      {name:"actions",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
          return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
             /> ); 
      }
      },headerStyle: {
        textAlign:'right'
      }}
     ];
            
     const options = {
       filterType: 'checkbox',
       selectableRows: 'none',
       display: "excluded"
      }




    return (
    <div className={classes.root}>

     <Paper className={classes.paper} style={{marginTop:'-15px'}}>
    <h2 className={classes.pageEntryLabel}>Collection Group Entry </h2>
      <Grid container spacing={2}>
      
        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
         inputRef={group_name_ref}
         onKeyDown={event => {
           if (event.key === "Enter") {
            employees_ref.current.focus()
           }
         }}
        label="Group Name" name="group_name" value={group_name} variant="outlined" size="small" onChange={(e)=>group_name_set(e.target.value)} />
        </Grid>




        {/* <Grid  item xs={12} sm={3} >
      


      <Autocomplete 
          autoHighlight={true}
          openOnFocus={true}
          multiple
          limitTags={10}
          id="multiple-limit-tags" 


          style={{ width: '100%' }}
          options={employees} 
          size="small"
          classes={{
            option: classes.option,
          }}
          getOptionLabel={(option) => option.employee_name}
          value={selectedEmployees}
          onChange={(event,selectedObj)=>{
            selectedEmployeesSet(selectedObj)
          }}
          renderInput={(params) => (
            <TextField
            inputRef={employees_ref}

              {...params}
              label="Select Employees"
              variant="outlined"
            
            />
          )}
          
      />
      </Grid> */}



<Grid  item xs={12} sm={3} >
      


      <Autocomplete 
          autoHighlight={true}
          openOnFocus={true}
          style={{ width: '100%' }}
          options={employees} 
          size="small"
          classes={{
            option: classes.option,
          }}
          getOptionLabel={(option) => option.employee_name}
          value={selectedEmployee}
          onChange={(event,selectedObj)=>{
            selectedEmployeeSet(selectedObj)
          }}
          renderInput={(params) => (
            <TextField
            inputRef={employees_ref}

              {...params}
              label="Select Employee"
              variant="outlined"
            
            />
          )}
          
      />
      </Grid>


      <Grid  item xs={12} sm={3} >
      


      <Autocomplete 
          autoHighlight={true}
          openOnFocus={true}

          style={{ width: '100%' }}
          options={components} 
          size="small"
          classes={{
            option: classes.option,
          }}
          getOptionLabel={(option) => option.component_name}
          value={selectedComponent}
          onChange={(event,selectedObj)=>{
            selectedComponentSet(selectedObj)
          }}
          renderInput={(params) => (
            <TextField
            inputRef={component_ref}

            onKeyDown={event => {
                if (event.key === "Enter") {
                 metting_day_ref.current.focus()
                }
              }}
              {...params}
              label="Select Component"
              variant="outlined"
            
            />
          )}
          
      />
      </Grid>




      <Grid  item xs={12} sm={3} >
      <Autocomplete 
          autoHighlight={true}
          openOnFocus={true}

          style={{ width: '100%' }}
          options={days} 
          size="small"
          classes={{
            option: classes.option,
          }}
          getOptionLabel={(option) => option.day}
          value={selectedDay}
          onChange={(event,selectedObj)=>{
            selectedDaySet(selectedObj)
          }}
          renderInput={(params) => (
            <TextField
            inputRef={metting_day_ref}

            onKeyDown={event => {
                if (event.key === "Enter") {
                    metting_place_ref.current.focus()
                }
              }}
              {...params}
              label="Select Metting Day"
              variant="outlined"
            
            />
          )}
          
      />
      </Grid>


      <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
         inputRef={metting_place_ref}
         onKeyDown={event => {
           if (event.key === "Enter") {
            saveFormAction()
           }
         }}
        label="Metting Place" name="metting_place" value={metting_place} variant="outlined" size="small" onChange={(e)=>metting_place_set(e.target.value)} />
        </Grid>


        <Grid item  xs={12}   sm={3} > 
          <Autocomplete 
           autoHighlight
           size="small"
             openOnFocus={true}
             style={{width:'100%',height:'20px'}}
             options={accounts}
             loading={loadingAccounts}
             value={selectedAccount}
             getOptionLabel={(option) => option.acc_name}
             onChange={(event,selectedObj)=>{
                selectedAccountSet(selectedObj) 
             }}
             renderInput={(params) => <TextField 
              label="Search Collection Account " 
               onChange={e => account_name_set(e.target.value)} 
               {...params} 
               InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loadingAccounts ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
               variant="outlined"
               />} 
          
      />
          </Grid>

  
  </Grid>



  
         <Grid item xs={12}>
  <Button style={{marginTop: '25px'}}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        disabled={loadingSave}
        startIcon={<SaveIcon />}
        onClick={saveFormAction}
      >
        Save
      </Button>
  </Grid>         


     </Paper>

     {
      loadingList==true?(<b>Loading...</b>):(
        <Paper className={classes.paper} style={{marginTop:'20px'}}>
        <MUIDataTable
      title={"Collection Group List"}
      data={groups}
      columns={columns}
      options={options}
      adjustForCheckbox={false} 
      displaySelectAll={false}
      />
      </Paper>
      )
      
     }

     
    </div>
        
    );
}


 const options = {
   filterType: 'checkbox',
 };
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },

root: {},
'& .MuiTextField-root': {
  margin: theme.spacing(1),
  width: '25ch',
 },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  },
  pageEntryLabel:{
      color: '#7754cc',
      margin: '0px',
      padding: '0px',
      marginTop: '-11px',
      textAlign: 'left',
      marginLeft: '0px',
      marginBottom: '5px'
  },
  fullWidth:{
      width:'100%'
  },
  option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    }
}));

const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(GroupManage);