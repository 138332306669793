import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux';

import {currentRouteSet} from '../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'; 
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import {pathSpliter,_p,sleep,dateTimeFormat,dateFormat,currentDateTime,getDateTimeFromISODT,currentDateStartTime,currentDateEndTime} from '../../lib/functions'
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DdatateIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DateFnsUtils from '@date-io/date-fns'; // choose your libs
import { APP_URL, API_URL } from "../../config.js";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import _ from 'lodash';
import ReactToPrint from "react-to-print";
import PrintIcon from '@material-ui/icons/Print';
import InstitutionProfile from '../commons/institution_profile'
import "../commons.css";

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';


import swal from 'sweetalert';
import commaNumber from 'comma-number';
import { TableFooter } from 'semantic-ui-react';
let format = commaNumber.bindWith(',', '.')
const SalesRecord = ({location,currentRoute,currentRouteSet,authInfo})=>{
    const classes = useStyles();
    const history = useHistory();

    useEffect(()=>{
        currentRouteSet(pathSpliter(location.pathname,1));
    },[]);

    let [report,reportSet] = useState([]);
    let [filterTypes,filterTypesSet] = useState([{filterType:'Lifetime'},{filterType:'Date Wise'}
  ]);

    let [selectedFilterType,selectedFilterTypeSet] = useState({filterType:'Date Wise'})


    let [reportLoading,reportLoadingSet] = useState(false);

    let [liabilities,liabilitiesSet] = useState({
        creditorBalance : 0,
        capitalBalance : 0,
        currentLiabilityBalance : 0,
        taxBalance : 0,
        profitLossBalance : 0,
        diffOpeningBalance : 0,
        loanBalance: 0,
        productTransferReceivedBalance: 0,
    })

    let [assets,assetsSet] = useState({
        cashBalance : 0,
        bankBalance : 0,
        debtorBalance : 0,
        fixedAssestsBalance : 0,
        currentAssetsBalance : 0,
        inventoryBalance : 0,
        productTransferBalance : 0,
    })
    

    

    

 
    let [byDateTime,byDateTimeSet] = useState({
      dateTimeFrom: '1900-06-11T07:21:47.572Z',
      dateTimeTo:currentDateEndTime()
})

    

    let [print,printSet] = useState(false);


    let getBalances = async ()=>{

      if(selectedFilterType == null){
        swal({
          title:'Select Filter Type',
          icon:'warning'
        })
        return false
      }

     
        reportLoadingSet(true)
        let url =  `${API_URL}/api/`;

        let payLoad = {
          type:'head_total',
          selectedFilterType: selectedFilterType != null?selectedFilterType.filterType:null,
        }

        if(selectedFilterType!=null && (selectedFilterType.filterType=='Date Wise' )){
          payLoad.fromDate = byDateTime.dateTimeFrom
          payLoad.toDate = byDateTime.dateTimeTo
        }

        await axios.post(`${url}get-balance-sheet`,payLoad,{headers:{'auth-token':authInfo.token}}).then(res=>{
         // Liabilities start
         liabilities.creditorBalance = res.data.creditorBalance;
         liabilities.capitalBalance = res.data.capitalBalance;
         liabilities.taxBalance = res.data.taxBalance;
         liabilities.profitLossBalance = res.data.profitLossBalance;
         liabilities.diffOpeningBalance = res.data.diffOpeningBalance;
         liabilities.loanBalance = res.data.loanBalance;
         liabilities.advanceDebtorBalance = res.data.advanceDebtorBalance
         liabilities.productTransferReceivedBalance = res.data.productTransferReceivedBalance
     
     // Liabilities  End

     // Assets
         assets.cashBalance = res.data.cashBalance;
         assets.bankBalance = res.data.bankBalance;
         assets.debtorBalance = res.data.debtorBalance;
         assets.fixedAssestsBalance = res.data.fixedAssestsBalance;
         assets.inventoryBalance = res.data.inventoryBalance;
         assets.receiveableBranchAmount = res.data.receiveableBranchAmount;
         assets.advanceCreditorBalance = res.data.advanceCreditorBalance;
         assets.productTransferBalance = res.data.productTransferBalance;
   

      })

        

       
        // Assets End
        printSet(true)
        reportLoadingSet(false)

    }


  

    let ReportDom = React.forwardRef( (props,ref) =>{
        return(
          <div ref={ref} >
                        <InstitutionProfile />

<div style={{clear:'both'}}></div>
<Paper style={{paddingLeft:'10px',paddingRight:'10px'}} className="print-source">
<p style={{width:'100%',textAlign:'center',fontWeight:'bold',
textAlign: 'center',
fontSize: '24px',
margin: '0',
padding: '0',
fontStyle: 'italic',
fontFamily: 'fantasy',
color: '#1c721c'}}>Balance Sheet</p>
<div style={{float:'left',width:'50%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>
</div>

<div style={{width:'10%'}}></div>

<div style={{float:'right',width:'40%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>
{/* <p style={{margin:'0px'}}>Statement  :  { moment(byDate.dateFrom).format(dateFormat)  } - { moment(byDate.dateTo).format(dateFormat) }</p> */}
</div>
 


</Paper> 

{
    
    <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}}>
    
    <Grid container spacing={3}>
       

         <Grid item  xs={12}   sm={6} >
        {/*  Start */}
         <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
        <TableHead>
          <TableRow style={{background: '#'}}> 
            <TableCell style={{color:'white !important',fontSize: '14px',fontWeight: 'bold'}} align="left"  width="70%">Liabilities</TableCell>
            <TableCell style={{color:'white !important',fontSize: '14px',fontWeight: 'bold'}} align="right" width="30%">Amount</TableCell> 
          </TableRow>
        </TableHead>
        <TableBody>
                <TableRow  key="1"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}> Supplier Due Balance </TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(liabilities.creditorBalance).toFixed(2))}</TableCell>
                </TableRow>

                <TableRow  key="2"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Capital Account </TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(liabilities.capitalBalance).toFixed(2))}</TableCell>
                </TableRow>

                <TableRow  key="2"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Loan  (Liability)</TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(liabilities.loanBalance).toFixed(2))}</TableCell>
                </TableRow>

                <TableRow  key="2"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Advance Received From Customer </TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(liabilities.advanceDebtorBalance).toFixed(2))}</TableCell>
                </TableRow>


                <TableRow  key="2"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Received Product Value From Branches </TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(liabilities.productTransferReceivedBalance).toFixed(2))}</TableCell>
                </TableRow>



                <TableRow  key="2"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Vat Balance</TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(liabilities.taxBalance).toFixed(2))}</TableCell>
                </TableRow>

                <TableRow  key="2">  
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}><Link to="/reports/profit-loss" style={{fontSize: '14px',fontWeight: 'bold',color:'#000000'}}>Profit/ Loss</Link> </TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(liabilities.profitLossBalance).toFixed(2))}</TableCell>
                </TableRow>

                <TableRow  key="2"> 
                <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Difference In Opening </TableCell>
                <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(liabilities.diffOpeningBalance).toFixed(2))}</TableCell>
                </TableRow>

                
                  
            <TableRow style={{background: 'rgb(221 78 78)'}}> 
            <TableCell   align="left"  width="5%" style={{fontWeight:'bold',color:'white !important',fontSize: '14px',fontWeight: 'bold'}}>Liabilities (Total)</TableCell>
            <TableCell style={{fontWeight:'bold',color:'white !important',fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}} align="right" width="15%">{authInfo.currency} {
                    format(parseFloat(
                    parseFloat(liabilities.creditorBalance)+parseFloat(liabilities.capitalBalance)
                    +parseFloat(liabilities.currentLiabilityBalance)+parseFloat(liabilities.taxBalance)
                    +parseFloat(liabilities.profitLossBalance)+parseFloat(liabilities.diffOpeningBalance)
                    + parseFloat(liabilities.loanBalance) + parseFloat(liabilities.advanceDebtorBalance)
                    + parseFloat(liabilities.productTransferReceivedBalance)
                    ).toFixed(2))
            }</TableCell> 
            </TableRow>

            </TableBody>
            </Table>
           </TableContainer>
           {/* End */}
         </Grid>

          <Grid item  xs={12}   sm={6} >
          <TableContainer >
            <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
                <TableHead>
                <TableRow> 
                    <TableCell align="left"  width="70%" style={{fontSize: '14px',fontWeight: 'bold'}}>Assets</TableCell>
                    <TableCell align="right" width="30%" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>Amount</TableCell> 
                </TableRow>
                </TableHead>
                <TableBody>

                       <TableRow  key="1"> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Cash In Hand</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(assets.cashBalance).toFixed(2))}</TableCell>
                        </TableRow>

                        <TableRow  key="1"> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Bank Accounts Balance</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(assets.bankBalance).toFixed(2))}</TableCell>
                        </TableRow>

                        <TableRow  key="1"> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Customer Due Balance</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(assets.debtorBalance).toFixed(2))}</TableCell>
                        </TableRow>

                        <TableRow  key="1"> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Fixed Assets</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(assets.fixedAssestsBalance).toFixed(2))}</TableCell>
                        </TableRow>

                        {/* <TableRow  key="1"> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Current Assets</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold'}}>{format(parseFloat(assets.currentAssetsBalance).toFixed(2))}</TableCell>
                        </TableRow> */}

<TableRow  key="1"> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Advance Payment to  Supplier</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(assets.advanceCreditorBalance).toFixed(2))}</TableCell>
                        </TableRow>
                        
                        <TableRow  key="1"> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Receiveable From Others Branches</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(assets.receiveableBranchAmount).toFixed(2))}</TableCell>
                        </TableRow>


                        <TableRow  key="1"> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Transfer Product Value To Others Branches</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(assets.productTransferBalance).toFixed(2))}</TableCell>
                        </TableRow>



                        <TableRow  key="1"> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Closing Product Inventory/ Stock Value</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(assets.inventoryBalance).toFixed(2))}</TableCell>
                        </TableRow>
                        
                    <TableRow style={{background: '#70a170'}}> 
                    <TableCell style={{fontWeight:'bold',fontSize: '14px',fontWeight: 'bold'}} align="left"  width="5%">Assets (Total)</TableCell>
                    <TableCell style={{fontWeight:'bold',fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}} align="right" width="15%">
                          {authInfo.currency}  {
                                 format(parseFloat(
                                    parseFloat(assets.cashBalance)+parseFloat(assets.bankBalance)
                                    +parseFloat(assets.debtorBalance)+parseFloat(assets.fixedAssestsBalance)
                                    +parseFloat(assets.currentAssetsBalance)+parseFloat(assets.inventoryBalance) 
                                    + parseFloat(assets.receiveableBranchAmount) 
                                    + parseFloat(assets.advanceCreditorBalance)
                                    + parseFloat(assets.productTransferBalance)
                                    ).toFixed(2))
                            }
                    </TableCell> 
                    </TableRow>

                    </TableBody>
                    </Table>
                </TableContainer>
          </Grid>
          
          </Grid>
    
        </Paper>
  
    
}
    


      
     
<div style={{clear: 'both',height:'10px'}}></div>
<div  className="print-source" style={{marginTop:'70px'}}>
                                <p style={{float:'left',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                           
                                 <p style={{float:'right',marginRight:'10px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
                      </div>
          </div>
        )
    })




    const reportRef = useRef();

   

      return(
          <div className={classes.root}> 
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'0px'}}>
<h4 style={{ textAlign: 'left',margin: "0px 0px  7px 0px",padding: '0px',color: '#222',textAlign: 'center',
    fontSize: '24px',
    margin: '0',
    padding: '0',
    fontStyle: 'italic',
    fontFamily: 'fantasy',
    color: '#1c721c',marginBottom:'20px'}}>Balance Sheet  { reportLoading == true ? ' --- Loading...':'' }</h4>


        </Paper>

        <Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'0px'}}>
            <Grid container>
                <Grid item  xs={12}  sm={2}> 
          <Autocomplete 
          size="small"
          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}}
              options={filterTypes} 
              value={selectedFilterType}
              getOptionLabel={(option) => option.filterType}
              onChange={(event,selectedObj)=>{
                selectedFilterTypeSet(selectedObj) 
              }}
              renderInput={(params) => <TextField 
               
                {...params} 
                label="Filter Type" 
                variant="outlined"
                />} 
          />

          </Grid>
              
          <div  style={{width: '20px'}} > </div>


          <Grid item  xs={12}  sm={2} style={{display: selectedFilterType!=null && (selectedFilterType.filterType=='Date Wise' )?'block':'none',display:'none'}} > 
          
          



          <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Stack spacing={3}>
      <DesktopDatePicker
        label="From Date" 
        inputFormat={dateTimeFormat}
        value={byDateTime.dateTimeFrom}
        onChange={(datet)=>{
          byDateTimeSet({...byDateTime,dateTimeFrom:datet})
        }}
        renderInput={(params) => <TextField {...params} />}
      />
      
    </Stack>
  </LocalizationProvider>


        </Grid>
        <div  style={{width: '20px'}} > </div>


        <Grid item  xs={12}  sm={2} style={{display: selectedFilterType!=null && (selectedFilterType.filterType=='Date Wise' )?'block':'none'}}  > 


        

<LocalizationProvider dateAdapter={AdapterDateFns}>
    <Stack spacing={3}>
      <DesktopDatePicker
        label="To Date" 
        inputFormat={dateTimeFormat}
        value={byDateTime.dateTimeTo}
        onChange={(datet)=>{
          byDateTimeSet({...byDateTime,dateTimeTo:datet})
        }}
        renderInput={(params) => <TextField {...params} />}
      />
      
    </Stack>
  </LocalizationProvider>

        </Grid>

        <div  style={{width: '20px'}} > </div>

        <Grid item  xs={12}   sm={1} >
      <Button style={{marginTop: '5px',marginLeft: 'auto',fontSize:'10px'}} 
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          startIcon={<SearchIcon/>}
                          onClick={getBalances}
                          disabled={reportLoading?true:false}
                      >
                      Report 
                    </Button>
      </Grid>
               
            </Grid>
        </Paper>


        <br/>
        <br/>


        {
          print?(
            <Grid container >
            <Grid item xs={12} sm={12} >
              <Paper style={{borderRadius:'0px',marginTop: '-17px'}}>
              <ReactToPrint
                          trigger={() => <PrintIcon  style={{cursor:'pointer',marginLeft: '30px',marginTop: '3px',marginBottom: '-8px'}} />}
                          content={() => reportRef.current}
                        />
              </Paper>
           
            </Grid>
        </Grid>
          ):''
        }
      
            <ReportDom ref={reportRef} /> 




          </div>
      )
}



const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        color: 'white',
        fontSize:'10px',
        backgroundColor: '#115f5f'
      },
    
    root: {
      flexGrow: 1,
    },
    table:{
      fontSize:'20px'
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(SalesRecord); 
