import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux';

import {currentRouteSet} from '../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'; 
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import {pathSpliter,_p,sleep,dateTimeFormat,dateFormat,currentDateTime,getDateTimeFromISODT,currentDateStartTime,currentDateEndTime} from '../../lib/functions'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DdatateIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DateFnsUtils from '@date-io/date-fns'; // choose your libs
import { APP_URL, API_URL } from "../../config.js";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import _ from 'lodash';
import ReactToPrint from "react-to-print";
import PrintIcon from '@material-ui/icons/Print';
import InstitutionProfile from '../commons/institution_profile'
import "../commons.css";

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';


import swal from 'sweetalert';
import commaNumber from 'comma-number';
import { TableFooter } from 'semantic-ui-react';
let format = commaNumber.bindWith(',', '.')
const TaxRecord = ({location,currentRoute,currentRouteSet,authInfo})=>{
    const classes = useStyles();
    useEffect(()=>{
        currentRouteSet(pathSpliter(location.pathname,1));
    },[]);

    let [report,reportSet] = useState([]);
    let [accounts,accountsSet] = useState([]);
    let [opening_balance,opening_balance_set] = useState(0);
    let [closing_balance,closing_balance_set] = useState(0);
    
    let [reportLoading,reportLoadingSet] = useState(false);
    

    let [selectedAccount,selectedAccountSet] = useState(null)
    

    

    let [byDate,byDateSet] = useState({
        dateFrom: currentDateStartTime(),
        dateTo:currentDateEndTime()
  })
    

    let [print,printSet] = useState(false);
    let [loadingAccounts,loadingAccountsSet] = useState(false);
    let [account_name,account_name_set] = useState('');

    
   
  


    useEffect(()=>{
          loadingAccountsSet(true)
          axios.post(`${API_URL}/api/get-accounts-by-type`,{acc_type_id:'dutie_&_tax'},{headers:{'auth-token':authInfo.token}}).then(res=>{
            accountsSet(res.data);
            if(res.data.length != 0){
                selectedAccountSet(res.data[0])
            }
            loadingAccountsSet(false)
          })
          
    },[account_name])


    



 




    
    let getSearchResult = async ()=>{

      if(selectedAccount == null){
        swal({
          title:'Select Account',
          icon:'warning'
        })
        return false
      }
     
        let url =  `${API_URL}/api/get-tax-ledger`;

         
            
        let reqPayload = {
            accId: selectedAccount != null? selectedAccount.acc_id:null,
            dateFrom: byDate.dateFrom,
            dateTo: byDate.dateTo
        }

        
        reportLoadingSet(true)
        await axios.post(`${url}`,{...reqPayload},{headers:{'auth-token':authInfo.token}}).then(res=>{
             printSet(true)
             reportLoadingSet(false)

             opening_balance_set(res.data.opening_balance)
             reportSet(res.data.ledger)
             closing_balance_set(res.data.closing_balance)
        })



    }




    let ReportDom = React.forwardRef( (props,ref) =>{
        return(
          <div ref={ref} >
                        <InstitutionProfile />

<div style={{clear:'both'}}></div>
<Paper style={{paddingLeft:'10px',paddingRight:'10px'}} className="print-source">
<p style={{width:'100%',textAlign:'center',fontWeight:'bold'}}>Dutie & Tax Account Ledger</p>
<div style={{float:'left',width:'50%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>
Account Name : {selectedAccount != null ? selectedAccount.acc_name :''}
</div>

<div style={{width:'10%'}}></div>

<div style={{float:'right',width:'40%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>
<p style={{margin:'0px'}}>Statement  :  { moment(byDate.dateFrom).format(dateFormat)  } - { moment(byDate.dateTo).format(dateFormat) }</p>
</div>
 


</Paper> 

{
    report.length > 0 || opening_balance != 0 ?(<>
    <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}}>
    
    {/* sale Record  without item */} 
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
        <TableHead>
          <TableRow> 
            <TableCell align="left"  width="5%">SL</TableCell>
            <TableCell align="left"  width="7%">Date</TableCell>
            <TableCell align="left"  width="20%">Particular</TableCell>
            <TableCell align="left"  width="7%">VCH No</TableCell>
            <TableCell align="left"  width="7%">VCH Type</TableCell>
            <TableCell align="right" width="15%">Debit </TableCell>
            <TableCell align="right" width="15%">Credit </TableCell>
            <TableCell align="right" width="15%"> Balance</TableCell> 
          </TableRow>

         
        </TableHead>

        
        <TableBody>

        <TableRow >
                <TableCell style={{fontWeight:'bold',textAlign: 'right'}}   colSpan={5} >Opening Balance : </TableCell>
                <TableCell style={{fontWeight:'bold',textAlign: 'right'}} ></TableCell>
                <TableCell style={{fontWeight:'bold',textAlign: 'right'}} >{format(parseFloat(opening_balance).toFixed(2))}</TableCell>
            </TableRow>
           
                {
                    report.map((acc,ind)=>(
                        <TableRow  key={ind}> 
                        <TableCell  align="left">{ind+parseFloat(1)}</TableCell>
                        <TableCell align="left">{moment(acc.creation_date).format(dateFormat)}</TableCell>
                        <TableCell  align="left">{acc.particular}</TableCell>
                        <TableCell  align="left">{acc.vch_no}</TableCell>
                        <TableCell  align="left">{acc.vch_type}</TableCell>
                        <TableCell  align="right">{format(parseFloat(acc.debit_amount).toFixed(2))}</TableCell>
                        <TableCell  align="right">{format(parseFloat(acc.credit_amount).toFixed(2))}</TableCell>
                        <TableCell  align="right">{format(parseFloat(acc.balance).toFixed(2))}</TableCell>
                       </TableRow>
                    ))
                }

              
              <TableRow >
                <TableCell style={{fontWeight:'bold'}} align="right" colSpan={5}>Grand Total : </TableCell>
                <TableCell style={{fontWeight:'bold'}} align="right">{format(parseFloat(_.sumBy(report,(acc)=>Number(acc.debit_amount))).toFixed(2))}</TableCell>
                <TableCell style={{fontWeight:'bold'}} align="right">{format(parseFloat(_.sumBy(report,(acc)=>Number(acc.credit_amount))).toFixed(2))}</TableCell>
                <TableCell  align="right"></TableCell>
                </TableRow>


                <TableRow >
                <TableCell style={{fontWeight:'bold'}} align="right" colSpan={5}>Closing Balance : </TableCell>
                <TableCell style={{fontWeight:'bold'}} align="right"></TableCell>
                <TableCell style={{fontWeight:'bold'}} align="right">{ format(parseFloat(closing_balance).toFixed(2))}</TableCell>
                <TableCell style={{fontWeight:'bold'}} align="right" colSpan={2}></TableCell>
                </TableRow>

        </TableBody>
 
      </Table>
    </TableContainer>
        </Paper>
  
    </>):''
    
}
    


      
     
<div style={{clear: 'both',height:'10px'}}></div>
<div  className="print-source" style={{marginTop:'70px'}}>
                                <p style={{float:'left',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                           
                                 <p style={{float:'right',marginRight:'10px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
                      </div>
          </div>
        )
    })




    const reportRef = useRef();

   

      return(
          <div className={classes.root}> 
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
<h4 style={{ textAlign: 'left',margin: "0px 0px  7px 0px",padding: '0px',color: '#222'}}>Dutie & Tax Account Ledger</h4>
<Grid container spacing={3} > 
        
          <Grid item  xs={12}   sm={3} > 
          <Autocomplete 
           autoHighlight
           size="small"
             openOnFocus={true}
             style={{width:'100%',height:'20px'}}
             options={accounts}
             loading={loadingAccounts}
             value={selectedAccount}
             getOptionLabel={(option) => option.acc_name}
             onChange={(event,selectedObj)=>{
                selectedAccountSet(selectedObj) 
             }}
             renderInput={(params) => <TextField 
              label="Search Account" 
               onChange={e => account_name_set(e.target.value)} 
               {...params} 
               InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loadingAccounts ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
               variant="outlined"
               />} 
          
      />
          </Grid>

          <Grid item  xs={12}  sm={2}  > 
          
          



          <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Stack spacing={3}>
      <DesktopDatePicker
        label="From Date" 
        inputFormat={dateTimeFormat}
        value={byDate.dateFrom}
        onChange={(datet)=>{
          byDateSet({...byDate,dateFrom:datet})
        }}
        renderInput={(params) => <TextField {...params} />}
      />
      
    </Stack>
  </LocalizationProvider>


        </Grid>


        <Grid item  xs={12}  sm={2}  > 


        

<LocalizationProvider dateAdapter={AdapterDateFns}>
    <Stack spacing={3}>
      <DesktopDatePicker
        label="To Date" 
        inputFormat={dateTimeFormat}
        value={byDate.dateTo}
        onChange={(datet)=>{
          byDateSet({...byDate,dateTo:datet})
        }}
        renderInput={(params) => <TextField {...params} />}
      />
      
    </Stack>
  </LocalizationProvider>

        </Grid>


       

          <Grid item  xs={12}   sm={2} >
        <Button style={{marginTop: '5px',marginLeft: 'auto',fontSize:'10px'}} 
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<SearchIcon/>}
                            onClick={getSearchResult}
                            disabled={reportLoading?true:false}
                        >
                        GET REPORT 
                      </Button>
        </Grid>
        


          </Grid>


        </Paper>
        {
          print?(
            <Grid container >
            <Grid item xs={12} sm={12} >
              <Paper style={{borderRadius:'0px',marginTop: '-17px'}}>
              <ReactToPrint
                          trigger={() => <PrintIcon  style={{cursor:'pointer',marginLeft: '30px',marginTop: '3px',marginBottom: '-8px'}} />}
                          content={() => reportRef.current}
                        />
              </Paper>
           
            </Grid>
        </Grid>
          ):''
        }
      
            <ReportDom ref={reportRef} /> 




          </div>
      )
}



const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        color: 'white',
        fontSize:'10px',
        backgroundColor: '#115f5f'
      },
    
    root: {
      flexGrow: 1,
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(TaxRecord);