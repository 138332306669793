import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import DashboardIcon from '@material-ui/icons/Dashboard';
import {
  Drawer, AppBar, Toolbar, List,
  ListItem, ListItemIcon, ListItemText,
  Menu, MenuItem, Badge, Fade,
  Divider, IconButton, BottomNavigation,
  BottomNavigationAction, Avatar
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import BuildIcon from '@material-ui/icons/Build';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ShopIcon from '@material-ui/icons/Shop';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';

import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import NoteIcon from '@material-ui/icons/Note';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import HomeIcon from '@material-ui/icons/Home';
import StyleIcon from '@material-ui/icons/Style';
import axios from 'axios';
import SettingsIcon from '@material-ui/icons/Settings';
import './global.css'
import { Modal } from 'react-responsive-modal';
import "react-responsive-modal/styles.css";
import TextField from '@material-ui/core/TextField';


import { APP_URL, API_URL } from '../config';
import { accessChecker } from '../lib/functions';
import {
  createdBranchSet, updatedBranchSet, disableRestoreBranchSet,
  createdWarehouseSet, updatedWarehouseSet, disableRestoreWarehouseSet,

} from '../actions/actions';
import swal from 'sweetalert';

// import dashboardIcon from 'public//assets/dashboard-removebg-preview.png'
import salesIcon from '../icons/sales.png'
import posIcon from '../icons/pos.png'
import serviceIcon from '../icons/service.png'
import orderIcon from '../icons/order.png'
import purchaseIcon from '../icons/purchase.png'
import manufacturingIcon from '../icons/manufacturing.png'
import inventoryIcon from '../icons/inventory.png'
import accountsIcon from '../icons/accounts.png'
import hrpayrollIcon from '../icons/hrpayroll.png'
import reportsIcon from '../icons/reports.png'
import quotationIcon from '../icons/quotation.png'
import settingIcon from '../icons/setting.png'
import crmIcon from '../icons/crm.png'





const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({

  root: {
    display: 'flex',
  },
  drawerIconColor: {
    color: 'rgb(255, 255, 255)'
  },
  linkStyle: {
    textDecoration: 'none',
    color: '#484848',
    paddingTop: '4px !important',
    paddingBottom: '4px !important'
  },
  appBar: {
    backgroundColor: '#e7e7e7',
    color: theme.topNavApp.color,
    borderBottom: "1px solid #9CE1E7",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    backgroundColor: '#ffffff',
    color: theme.topNavApp.color,
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: theme.sidebar,
  },
  drawerOpen: {
    width: drawerWidth,
    background: 'linear-gradient(to left, #ffffff  0%, #ffffff  100%)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(0) + 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(0) + 0,
    },
  },
  toolbarspace: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  toolbar: {
    backgroundColor: '#e7e7e7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  companyTitle: {
    textAlign: 'left',
    fontSize: '13px',
    marginRight: '-15px',
    color: 'black',
    fontFamily: 'Calibri',
    fontStyle: 'bold'

  },
  '@global': {

    '.MuiListItem-gutters': {
      paddingLeft: '5px',
      paddingRight: '0px'
    },

    '.MuiTableCell-head:last-child': {
      textAlign: 'right'
    },
    '.MuiTableCell-head:first-child': {
      textAlign: 'left'
    },

    '.MuiAlert-standardSuccess': {
      color: '#ffff !important',
      fontSize: '15px !important',
      backgroundColor: 'green !important',
      fontWeight: 'bold !important'
    },

    '.MuiTableCell-root': {
      padding: '1px !important'
    },
    '.MuiTypography-h6': {
      'textAlign': 'left',
      'marginLeft': '-22px'
    },
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    '.MuiBottomNavigation-root': {
      background: 'transparent'
    },
    '.MuiListItem-root': {
      paddingBottom: '1px',
      paddingTop: '1px'
    },
    '.MuiListItemIcon-root': {
      minWidth: '30px'
    },
    'a': {
      textDecoration: 'none'
    },
    '.MuiInputBase-input': {
      width: '100% !important'
    },
    '.MuiButton-containedPrimary': {
      backgroundColor: '#188074 !important',
      color: '#005d1f',
      backgroundColor: '#95f3ff'
    },
    '.MuiButton-containedPrimary:hover': {
      backgroundColor: '#188074 !important',
      color: '#005d1f',
      backgroundColor: '#65da63'
    },
    '.MuiOutlinedInput-input': {
      padding: '10.5px 14px !important'
    },
    '.MuiInputLabel-outlined': {
      zIndex: '1',
      transform: 'translate(14px, 13px) scale(1)',
      pointerEvents: 'none'
    },
    '.MuiDrawer-paperAnchorLeft': {
      overflowX: 'hidden'
    },
    '.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
      padding: '0px !important'
    }
  },
  usersettingaction: {
    marginLeft: 0,
    cursor: 'pointer',
    marginLeft: '15px',
    marginRight: '15px'
  },
  whiteSpace: {
    width: '100%',
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}));
const Header = ({ path, menuAction, currentRoute, createdCategorySet,
  updatedCategorySet, disableRestoreSet, createdBrandSet, updatedBrandSet, brandDisableRestoreSet,
  createdColorSet, updatedColorSet, disableRestoreColorSet, createdUnitSet, updatedUnitSet, disableRestoreUnitSet,
  createdBranchSet, updatedBranchSet, disableRestoreBranchSet, createdWarehouseSet, updatedWarehouseSet, disableRestoreWarehouseSet,
  createdAreaSet, updatedAreaSet, disableRestoreAreaSet,
  createdProdNameSet, updatedProdNameSet, disableRestoreProdNameSet, authInfo,
  createdMaterialNameSet, updatedMaterialNameSet, disableRestoreMaterialNameSet,
  createdProductSet, updatedProductSet, productCodeSet, disableRestoreProductSet, customerCodeSet,
  createdCustomerSet, updatedCustomerSet, disableRestoreCustomerSet,
  createdSupplierSet, updatedSupplierSet, disableRestoreSupplierSet,
  createdDesignationSet, updatedDesignationSet, disableRestoreDesignationSet,
  createdDepartmentSet, updatedDepartmentSet, disableRestoreDepartmentSet,
  createdMonthSet, updatedMonthSet, disableRestoreMonthSet,
  createdEmployeeSet, updatedEmployeeSet, employeeDisableRestoreSet,
  employeeCodeSet,
  createdTranAccSet, updatedTranAccSet, tranAccDisableRestoreSet, tranAccCodeSet,
  createdBankAccSet, bankAccCodeSet,
  createdCashTranSet, updatedCashTranSet, cashTranDisableRestoreSet, cashTranCodeSet,
  createdBankTranSet, updatedBankTranSet, bankTranDisableRestoreSet, bankTranCodeSet,
  createdCustomerPaySet, updatedCustomerPaySet, customerPayDisableRestoreSet,
  createdSupplierPaySet, updatedSupplierPaySet, supplierPayDisableRestoreSet,
  createdMaterialSet, updatedMaterialSet, materialCodeSet, disableRestoreMaterialSet,


}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [value, setValue] = React.useState(0);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openusersetting = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const [branchSwitch, branchSwitchSet] = React.useState(false);
  const [branches, branchesSet] = React.useState([]);
  const [selectedBranch, selectedBranchSet] = React.useState(null);
  const [user_password, user_password_set] = React.useState('');
  let [ajaxReqStatus, ajaxReqStatusSet] = useState(false);





  const logout = () => {
    sessionStorage.clear();
    window.location.href = `${APP_URL}`
  }

  useEffect(() => {
    if (branches.length == 0) {
      getBranches()

    }


  }, [])







  let actionToSwitch = async () => {
    if (selectedBranch == null) {
      swal({
        title: 'Select a Switching Branch',
        icon: 'warning'
      })
    } else if (user_password.trim() == '') {
      swal({
        title: 'Your current account password is Required.',
        icon: 'warning'
      })
    } else {
      ajaxReqStatusSet(true)
      let new_branch_id = selectedBranch.branch_id;
      let new_branch_name = selectedBranch.branch_name;
      await axios.post(`${API_URL}/api/switch-branch`, { user_password, new_branch_id, new_branch_name }, { headers: { 'auth-token': authInfo.token } }).then(res => {
        ajaxReqStatusSet(false)
        //  return false
        if (res.data.error == false) {
          sessionStorage.setItem('auth_info', JSON.stringify(res.data));

          window.location.reload();
          window.location.href = `${APP_URL + 'sales'}`;


        } else {
          swal({
            title: `${res.data.message}`,
            icon: 'warning'
          })
        }
      })

    }
  }





  let getBranches = async () => {
    await axios.post(`${API_URL}/api/get-branches`, null, { headers: { 'auth-token': authInfo.token } }).then((res) => {
      let data = res.data.message


      branchesSet(data);

    })
  }




  return (
    <div className="app-gap">
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >



        <Toolbar>



          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>


          {
            authInfo.acc_type != 'customer' ? (

              <BottomNavigation
                value={value}
                className={classes.Mui}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                showLabels
                className={classes.root}
              >



                <BottomNavigationAction component={Link} to="/" label="Dashboard" style={{ color: 'black', fontSize: '2rem' }} icon={<img src="/assets/dashboard-removebg-preview.png" alt="Dashboard" style={{ width:'30px', height:'30px'}} />} />
                
                <BottomNavigationAction {...(path == 'sales' ? '' : '')} component={Link}
                  to="/sales" label="Sales" style={{ color: 'black' }} icon={<img src="/assets/sales-removebg-preview.png"alt="Sales" style={{ width:'30px', height:'30px'}} />} />


                <BottomNavigationAction {...(path == 'sales' ? '' : '')} component={Link}
                  to="/sales/pos-entry" label="POS" style={{ color: 'black' }} icon={<img src="/assets/pos-removebg-preview.png"alt="POS" style={{ width:'30px', height:'30px'}} />} />






                <BottomNavigationAction component={Link} to="/quotation" label="Quotation" style={{ color: 'black' }} icon={<img style={{
                  width:'30px', height:'30px'
                }} src="/assets/Quotation-removebg-preview.png" alt="Quotation" />} />
                <BottomNavigationAction component={Link} to="/order" label="Order" style={{ color: 'black' }} icon={<img src="/assets/order-removebg-preview.png" alt="Order" style={{width:'30px', height:'30px'}} />} />

                <BottomNavigationAction component={Link} to="/service" label="Service" style={{ color: 'black' }} icon={<img src="/assets/service-removebg-preview.png" alt="Service" style={{width:'30px', height:'30px'}} />} />

                <BottomNavigationAction component={Link} to="/purchase" label="Purchase" style={{ color: 'black' }} icon={<img src="/assets/purchase-removebg-preview.png" alt="Purchase" style={{width:'30px', height:'30px'}} />} />

                <BottomNavigationAction component={Link} to="/manufacturing" label="Manufacturing" style={{ color: 'black',marginLeft:'10px' }} icon={<img src="/assets/manyfacturing1-removebg-preview.png" alt="Manufacturing" style={{width:'30px', height:'30px'}} />} />
                <BottomNavigationAction component={Link} to="/inventory" label="Inventory" style={{ color: 'black',marginLeft:'10px' }} icon={<img src="/assets/inventory-removebg-preview.png" alt="Inventory" style={{width:'30px', height:'30px'}} />} />
                <BottomNavigationAction component={Link} to="/accounts" label="Accounts" style={{ color: 'black',marginTop:'5px'  }} icon={<img src="/assets/account-removebg-preview.png" alt="Accounts" style={{width:'27px', height:'27px'}} />} />
                <BottomNavigationAction component={Link} to="/hrpayroll" label="HR&Payroll" style={{ color: 'black',marginTop:'3px' }} icon={<img src="/assets/hr-removebg-preview-removebg-preview.png" alt="Payroll" style={{width:'30px', height:'30px'}} />} />
                <BottomNavigationAction component={Link} to="/reports" label="Reports" style={{ color: 'black',marginTop:'3px' }} icon={<img src="/assets/report-removebg-preview.png" alt="Reports" style={{width:'30px', height:'30px'}} />} />
                <BottomNavigationAction component={Link} to="/crm" label="CRM" style={{ color: 'black',marginLeft:'-10px',marginTop:'3px' }} icon={<img src="/assets/crm-removebg-preview.png" alt="CRM" style={{width:'30px', height:'30px'}} />} />
                <BottomNavigationAction component={Link} to="/settings" label="Settings" style={{ color: 'black',marginLeft:'-10px',marginTop:'3px' }} icon={<img src="/assets/settings-removebg-preview.png" alt="Setting" style={{width:'30px', height:'30px'}} />} />

                <ArrowUpwardIcon component={Link} to="/settings" label="Settings"
                  style={{ color: 'black' }} icon={<img src="/assets/settings-removebg-preview.png" alt="Setting" style={{width:'30px', height:'30px'}} />} />


                {
                  authInfo.role == 'super_admin' ? (<span>
                    <BottomNavigationAction component={Link} to="#" onClick={() => branchSwitchSet(true)} label="Switch"
                      style={{ color: '#00691D' }} icon={<ArrowUpwardIcon style={{ fontSize: '2rem' }} />} />
                  </span>) : ''
                }


              </BottomNavigation>

            ) : ''
          }
          {/*  */}


          <IconButton style={{ display: 'none' }} aria-label="show 17 new notifications" color="inherit">
            <Badge badgeContent={17} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <div className={classes.root, classes.usersettingaction} aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
            <Avatar>{authInfo.userInfo.user_full_name.substring(0, 1)} </Avatar>
          </div>
          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={openusersetting}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem> */}
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>

          {/*  */}
        </Toolbar>


      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar} >
          <div style={{ display:'flex' }}>
            <img src='/assets/logo-removebg-preview.png' style={{width:'50px',height:'30px', marginRight:'3px', marginTop:'13px'}}  />
            <h1 className={classes.companyTitle}  >Express Accounting <br /> (<a style={{ color: 'black' }} href="http://soft-task.com" target='_blank'>Imbuesoft</a>)</h1><br />

            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
        </div>
        <List>



          {
            currentRoute == 'quotation' && authInfo.acc_type != 'customer' ? (<>
              <Link to="/quotation" className={classes.linkStyle} >
                <ListItem button key="HomeIcon">
                  <ListItemText className="module-text" primary={"Quotation Module"} />
                </ListItem>
              </Link>


              {
                accessChecker('quotation_entry') > -1 ? (
                  <>
                    <Link to="/quotation/quotation-entry" className={classes.linkStyle}>
                      <ListItem button key="PermDataSettingIcon">
                        <ListItemText primary={"Quotation  Entry"} />
                      </ListItem>
                    </Link>
                  </>
                ) : ''
              }



              {
                accessChecker('quotation_entry') > -1 ? (
                  <>
                    <Link to="/quotation/quotation-record" className={classes.linkStyle}>
                      <ListItem button key="PermDataSettingIcon">
                        <ListItemText primary={"Quotation  Record"} />
                      </ListItem>
                    </Link>

                  </>
                ) : ''
              }


            </>) : ''
          }


          {
            currentRoute == 'sales' && authInfo.acc_type != 'customer' ? (
              <>
                <Link to="/sales" className={classes.linkStyle} >
                  <ListItem button key="HomeIcon">
                    <ListItemText className="module-text" primary={"Sales Module"} />
                  </ListItem>
                </Link>

                {
                  accessChecker('sales_entry') > -1 ? (
                    <>
                      <Link to="/sales/sales-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Sales  Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('pos_entry') > -1 ? (
                    <>
                      <Link to="/sales/pos-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"POS  Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('sales_return') > -1 ? (
                    <>

                      <Link to="/sales/sales-return-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Sales Return Entry"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }


                {
                  accessChecker('product_replace') > -1 ? (
                    <>
                      <Link to="/sales/product-replace" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Replace  Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('sales_voucher') > -1 ? (
                    <>
                      <Link to="/sales/view-sales-voucher" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"View Sales  Vouchers"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('sales_return_voucher') > -1 ? (
                    <>
                      <Link to="/sales/view-sales-return-voucher" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Sales Return Vouchers"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }




                {
                  accessChecker('sales_record') > -1 ? (
                    <>
                      <Link to="/sales/sales-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Sales  Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('sales_return_record') > -1 ? (
                    <>
                      <Link to="/sales/sales-return-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Sales Return Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('product_replace_record') > -1 ? (
                    <>
                      <Link to="/sales/product-replace-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Replace Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }





              </>
            ) : ''
          }



          {
            currentRoute == 'order' ? (<>
              <Link to="/order" className={classes.linkStyle} >
                <ListItem button key="HomeIcon">
                  <ListItemText className="module-text" primary={" Order Module"} />
                </ListItem>
              </Link>

              {
                accessChecker('sales_order_entry') > -1 ? (
                  <>
                    <Link to="/order/sales-order-entry" className={classes.linkStyle}>
                      <ListItem button key="PermDataSettingIcon">
                        <ListItemText primary={"Sales order Entry"} />
                      </ListItem>
                    </Link>
                  </>
                ) : ''
              }


              {
                accessChecker('purchase_order_entry') > -1 ? (
                  <>
                    <Link to="/order/purchase-order-entry" className={classes.linkStyle}>
                      <ListItem button key="PermDataSettingIcon">
                        <ListItemText primary={"purchase order entry"} />
                      </ListItem>
                    </Link>
                  </>
                ) : ''
              }



              {
                accessChecker('sales_order_voucher') > -1 ? (
                  <>
                    <Link to="/order/view-sales-order-voucher" className={classes.linkStyle}>
                      <ListItem button key="PermDataSettingIcon">
                        <ListItemText primary={"Sales order vouchers"} />
                      </ListItem>
                    </Link>
                  </>
                ) : ''
              }




              {
                accessChecker('purchase_order_voucher') > -1 ? (
                  <>
                    <Link to="/order/view-purchase-order-voucher" className={classes.linkStyle}>
                      <ListItem button key="PermDataSettingIcon">
                        <ListItemText primary={"purchase order Vouchers"} />
                      </ListItem>
                    </Link>
                  </>
                ) : ''
              }




              {
                accessChecker('purchase_order_record') > -1 ? (
                  <>
                    <Link to="/order/purchase-order-record" className={classes.linkStyle}>
                      <ListItem button key="PermDataSettingIcon">
                        <ListItemText primary={"purchase order record"} />
                      </ListItem>
                    </Link>

                  </>
                ) : ''
              }





              {
                accessChecker('sales_order_record') > -1 ? (
                  <>
                    <Link to="/order/sales-order-record" className={classes.linkStyle}>
                      <ListItem button key="PermDataSettingIcon">
                        <ListItemText primary={"sales order record"} />
                      </ListItem>
                    </Link>
                  </>
                ) : ''
              }













            </>) : ''
          }

          {
            currentRoute == 'service' ? (
              <>
                <Link to="/service" className={classes.linkStyle} >
                  <ListItem button key="HomeIcon">
                    <ListItemText className="module-text" primary={"Service Module"} />
                  </ListItem>
                </Link>

              </>
            ) : ''
          }

          {
            currentRoute == 'purchase' ? (
              <>


                {
                  accessChecker('purchase_entry') > -1 ? (
                    <>
                      <Link to="/purchase" className={classes.linkStyle} >
                        <ListItem button key="HomeIcon">
                          <ListItemText className="module-text" primary={"Purchase Module"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('purchase_entry') > -1 ? (
                    <>
                      <Link to="/purchase/purchase-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"purchase  entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('purchase_return') > -1 ? (
                    <>
                      <Link to="/purchase/purchase-return-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"purchase return entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('purchase_voucher') > -1 ? (
                    <>
                      <Link to="/purchase/view-purchase-voucher" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"purchase  Vouchers"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('purchase_return_voucher') > -1 ? (
                    <>
                      <Link to="/purchase/view-purchase-return-voucher" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"purchase return  Vouchers"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }





                {
                  accessChecker('purchase_record') > -1 ? (
                    <>
                      <Link to="/purchase/purchase-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"purchase  record"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }




                {
                  accessChecker('purchase_return_record') > -1 ? (
                    <>
                      <Link to="/purchase/purchase-return-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"purchase return record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }






              </>
            ) : ''
          }

          {
            currentRoute == 'manufacturing' ? (
              <>
                <Link to="/manufacturing" className={classes.linkStyle} >
                  <ListItem button key="HomeIcon">
                    <ListItemText className="module-text" primary={"Manufacturing Module"} />
                  </ListItem>
                </Link>
              </>
            ) : ''
          }

          {
            currentRoute == 'financial-accounts' ? (
              <>
                <Link to="/accounts" className={classes.linkStyle} style={{ fontSize: '14px' }}>
                  <ListItem button key="HomeIcon" style={{ fontSize: '14px' }}>
                    <ListItemText className="module-text" primary={"Financial Accounts"} />
                  </ListItem>
                </Link>
              </>
            ) : ''
          }



          {
            currentRoute == 'inventory' ? (
              <>





                <Link to="/inventory" className={classes.linkStyle} >
                  <ListItem button key="HomeIcon">
                    <ListItemText className="module-text" primary={"Inventory Module"} />
                  </ListItem>
                </Link>


                {
                  accessChecker('item_stock_report') > -1 ? (
                    <>
                      <Link to="/inventory/item-stock-report" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Item Stock Report"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('item_ledger') > -1 ? (
                    <>
                      <Link to="/inventory/item-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Item Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }

                {
                  accessChecker('transfer_entry') > -1 ? (
                    <>
                      <Link to="/inventory/transfer-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Transfer Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('item_adjustment_entry') > -1 ? (
                    <>
                      <Link to="/inventory/item-adjustment" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Item Adjustment Enty"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }


                {
                  accessChecker('adjustment_record') > -1 ? (
                    <>
                      <Link to="/inventory/adjustment-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={" Adjustment Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('transfer_record') > -1 ? (
                    <>
                      <Link to="/inventory/transfer-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Transfer Record"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }


                {
                  accessChecker('transfer_pending_record') > -1 ? (
                    <>
                      <Link to="/inventory/transfer-pending-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Transfer Pending Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('transfer_receive_record') > -1 ? (
                    <>
                      <Link to="/inventory/transfer-receive-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Transfer Receive Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }





              </>
            ) : ''
          }








          {
            currentRoute == 'reports' && authInfo.acc_type != 'customer' ? (
              <>
                <Link to="/reports" className={classes.linkStyle} >
                  <ListItem button key="HomeIcon">
                    <ListItemText className="module-text" primary={"Reports Module"} />
                  </ListItem>
                </Link>



                {
                  accessChecker('debtor_balance') > -1 ? (
                    <>
                      <Link to="/reports/debitors-balance" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Customer Due Balance"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }

                {
                  accessChecker('creditor_balance') > -1 ? (
                    <>
                      <Link to="/reports/creditors-balance" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Supplier Due Balance"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }



                {
                  accessChecker('debtor_ledger') > -1 ? (
                    <>
                      <Link to="/reports/debitor-balance-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Customer  Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }

                {
                  accessChecker('creditor_ledger') > -1 ? (
                    <>
                      <Link to="/reports/creditor-balance-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Supplier  Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }


                {
                  accessChecker('collection_record') > -1 ? (
                    <>
                      <Link to="/reports/collection-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Sales & Collection Record"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }

                {
                  accessChecker('debtor_receipt_record') > -1 ? (
                    <>
                      <Link to="/reports/debtor-receipt-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Customer Receipt Record"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }



                {
                  accessChecker('cash_bank_balance') > -1 ? (
                    <>
                      <Link to="/reports/account-balance" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Cash & Bank Balance"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('cash_bank_ledger') > -1 ? (
                    <>
                      <Link to="/reports/account-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Cash & Bank  Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }

                {
                  accessChecker('indirect_expense_balance') > -1 ? (
                    <>
                      <Link to="/reports/indirect-expenses-balance" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Expense Balance Report"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('expense_record') > -1 ? (
                    <>
                      <Link to="/reports/expense-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Expense Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }




                {
                  accessChecker('daily_ledger') > -1 ? (
                    <>
                      <Link to="/reports/daily-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Daily   Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }





                {
                  accessChecker('item_stock_report') > -1 ? (
                    <>
                      <Link to="/reports/item-stock-report" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Item Stock Report"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('profit_loss') > -1 ? (
                    <>
                      <Link to="/reports/profit-loss" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Profit Loss"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('item_wise_profit_loss') > -1 ? (
                    <>
                      <Link to="/reports/item-wise-profit" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Item Wise Profit/ Loss"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }



                {
                  accessChecker('balance_sheet') > -1 ? (
                    <>
                      <Link to="/reports/balance-sheet" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Balance Sheet"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }



                {
                  accessChecker('trial_balance') > -1 ? (
                    <>
                      <Link to="/reports/trial-balance" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Trial Balance"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }








                {
                  accessChecker('capital_balance') > -1 ? (
                    <>
                      <Link to="/reports/capitals-balance" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Capitals Balance"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('loan_balance') > -1 ? (
                    <>
                      <Link to="/reports/loan-balance" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Loan Accounts Balance"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('sheet_print_of_collection') > -1 ? (
                    <>
                      <Link to="/reports/sheet-print-of-collection" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"sheet print collection"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }



                {
                  accessChecker('branch_tran_pen_list') > -1 ? (
                    <>
                      <Link to="/reports/branch-tran-pending-list" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Branch Tran Pending List"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('branch_tran_rcv_list') > -1 ? (
                    <>
                      <Link to="/reports/branch-tran-receive-list" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Branch Tran Received List"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('branch_tran_transfer_list') > -1 ? (
                    <>
                      <Link to="/reports/branch-tran-transfer-list" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Branch Tran Transfer List"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('advance_debtor_balance') > -1 ? (
                    <>
                      <Link to="/reports/advance-debtor-balance" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Advance Customer Balance"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('advance_creditor_balance') > -1 ? (
                    <>
                      <Link to="/reports/advance-creditor-balance" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Advance Supplier Balance"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }






                {
                  accessChecker('indirect_income_balance') > -1 ? (
                    <>
                      <Link to="/reports/indirect-income-balance" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Indirect Income Balance"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('fixed_asset_balance') > -1 ? (
                    <>
                      <Link to="/reports/fixed-asset-balance" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Fixed Asset Balance"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }


                {/* {
  accessChecker('debtor_balance') > -1?(
     <> */}
                <Link to="/reports/emi-due-list" className={classes.linkStyle}>
                  <ListItem button key="PermDataSettingIcon">
                    <ListItemText primary={"Customer EMI Due List"} />
                  </ListItem>
                </Link>

                {/* </>
  ):''
} 
                  */}




                {
                  accessChecker('branch_balance') > -1 ? (
                    <>
                      <Link to="/reports/branch-balance-report" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Branch Balance Report"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }







                {
                  accessChecker('fixed_asset_ledger') > -1 ? (
                    <>
                      <Link to="/reports/fixed-asset-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Fixed  asset  Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }


                {
                  accessChecker('loan_ledger') > -1 ? (
                    <>
                      <Link to="/reports/loan-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Loan Account  Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }


                {
                  accessChecker('indirect_expense_ledger') > -1 ? (
                    <>
                      <Link to="/reports/indirect-expense-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Indirect Expense  Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }


                {
                  accessChecker('indirect_income_ledger') > -1 ? (
                    <>
                      <Link to="/reports/indirect-income-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Indirect Income  Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }



                {
                  accessChecker('capital_ledger') > -1 ? (
                    <>
                      <Link to="/reports/capital-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Capital  Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }




                {
                  accessChecker('branch_ledger') > -1 ? (
                    <>
                      <Link to="/reports/branch-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Branch Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }





                {
                  accessChecker('sales_ledger') > -1 ? (
                    <>
                      <Link to="/reports/sales-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Sales Account Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }




                {
                  accessChecker('purchase_ledger') > -1 ? (
                    <>
                      <Link to="/reports/purchase-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Purchase Account Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }

                {
                  accessChecker('service_expense_ledger') > -1 ? (
                    <>
                      <Link to="/reports/service-expense-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Service Expense Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }


                {
                  accessChecker('service_ledger') > -1 ? (
                    <>
                      <Link to="/reports/service-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Services Account Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }


                {
                  accessChecker('sales_return_ledger') > -1 ? (
                    <>
                      <Link to="/reports/sales-return-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Sales Return acc. Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }


                {
                  accessChecker('purchase_return_ledger') > -1 ? (
                    <>
                      <Link to="/reports/purchase-return-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Purchase Return acc. Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }



                {
                  accessChecker('tax_ledger') > -1 ? (
                    <>
                      <Link to="/reports/tax-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Dutie & Tax Acc. Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }


                {
                  accessChecker('direct_expense_balance') > -1 ? (
                    <>
                      <Link to="/reports/direct-expense-balance" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Direct Expense Balance"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }


                {
                  accessChecker('direct_expense_ledger') > -1 ? (
                    <>
                      <Link to="/reports/direct-expense-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Direct Expense Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }







                {
                  accessChecker('creditor_payment_record') > -1 ? (
                    <>
                      <Link to="/reports/creditor-payment-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Supplier Payment Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }



                {
                  accessChecker('advance_tran_record') > -1 ? (
                    <>
                      <Link to="/reports/advance-tran-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Advance Tran Record"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }



                {
                  accessChecker('expense_recognition_record') > -1 ? (
                    <>
                      <Link to="/reports/expense-recognition-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Expense Recognition Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('income_record') > -1 ? (
                    <>
                      <Link to="/reports/income-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Income Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }






                {
                  accessChecker('journal_record') > -1 ? (
                    <>
                      <Link to="/reports/journal-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Journal Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('contra_record') > -1 ? (
                    <>
                      <Link to="/reports/contra-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Contra Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }



                {
                  accessChecker('sales_record') > -1 ? (
                    <>
                      <Link to="/reports/sales-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Sales Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('sales_return_record') > -1 ? (
                    <>
                      <Link to="/reports/sales-return-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Sales Return Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('purchase_record') > -1 ? (
                    <>
                      <Link to="/reports/purchase-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Purchase Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('purchase_return_record') > -1 ? (
                    <>
                      <Link to="/reports/purchase-return-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Purchase Return Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('production_record') > -1 ? (
                    <>
                      <Link to="/reports/manufacturing-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Manufacturing Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('salary_report') > -1 ? (
                    <>
                      <Link to="/reports/salary-report" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Salary Payment Report"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }



                {
                  accessChecker('direct_income_balance') > -1 ? (
                    <>
                      <Link to="/reports/direct-income-balance" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Direct Income Balance"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }

                {
                  accessChecker('direct_income_ledger') > -1 ? (
                    <>
                      <Link to="/reports/direct-income-ledger" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Direct Income Ledger"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }


                {
                  accessChecker('salary_report') > -1 ? (
                    <>
                      <Link to="/reports/salary-report" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Salary Payment Report"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }


                {
                  accessChecker('monthly_salary_report') > -1 ? (
                    <>
                      <Link to="/reports/monthly-salary-report" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"monthly salary report"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }

              </>
            ) : ''
          }

          {
            currentRoute == 'hrpayroll' ? (
              <>
                <Link to="/hrpayroll" className={classes.linkStyle} >
                  <ListItem button key="HomeIcon">
                    <ListItemText className="module-text" primary={"HR&Payroll Module"} />
                  </ListItem>
                </Link>
              </>
            ) : ''
          }

          {
            currentRoute == 'settings' ? (
              <>
                <Link to="/settings" className={classes.linkStyle} >
                  <ListItem button key="HomeIcon">
                    <ListItemText className="module-text" primary={"Settings"} />
                  </ListItem>
                </Link>
              </>
            ) : ''
          }



          {
            (currentRoute == '' && authInfo.acc_type != 'customer') || currentRoute == 'dashboard' ? (
              <Fragment>
                <Link to="/sales" className={classes.linkStyle} >
                  <ListItem button key="StyleIcon">
                    <ListItemIcon><img src="/assets/sales-removebg-preview.png" style={{ width: '29px', height: '20px' }} alt="Sales" /></ListItemIcon>
                    <ListItemText primary={"Sales Module"} />
                  </ListItem>
                </Link>
                <Link to="/sales/pos-entry" className={classes.linkStyle} >
                  <ListItem button key="StyleIcon">
                    <ListItemIcon><img src="/assets/pos-removebg-preview.png" style={{ width: '29px', height: '20px' }} alt="POS" /></ListItemIcon>
                    <ListItemText primary={"POS"} />
                  </ListItem>
                </Link>





                <Link to="/quotation" className={classes.linkStyle} >
                  <ListItem button key="StyleIcon">
                    <ListItemIcon><img src="/assets/Quotation-removebg-preview.png" style={{
                      width: '29px', height: '20px'
                    }} alt="Sales" /></ListItemIcon>
                    <ListItemText primary={"Quotation Module"} />
                  </ListItem>
                </Link>

                <Link to="/order" className={classes.linkStyle} >
                  <ListItem button key="StyleIcon">
                    <ListItemIcon><img src="/assets/order-removebg-preview.png" style={{ width: '29px', height: '20px' }} alt="Sales" /></ListItemIcon>
                    <ListItemText primary={"Order Module"} />
                  </ListItem>
                </Link>

                <Link to="/service" className={classes.linkStyle} >
                  <ListItem button key="StyleIcon">
                    <ListItemIcon><img src="/assets/service-removebg-preview.png" style={{ width: '29px', height: '20px' }} alt="Sales" /></ListItemIcon>
                    <ListItemText primary={"Service Module"} />
                  </ListItem>
                </Link>

                <Link to="/purchase" className={classes.linkStyle} >
                  <ListItem button key="StyleIcon">
                    <ListItemIcon><img src="/assets/purchase-removebg-preview.png" style={{ width: '25px', height: '20px' }} alt="Sales" /></ListItemIcon>
                    <ListItemText primary={"Purchase Module"} />
                  </ListItem>
                </Link>

                <Link to="/manufacturing" className={classes.linkStyle} >
                  <ListItem button key="StyleIcon">
                    <ListItemIcon><img src="/assets/manyfacturing1-removebg-preview.png" style={{ width: '23px', height: '20px' }} alt="Sales" /></ListItemIcon>
                    <ListItemText primary={"Manufacturing Module"} />
                  </ListItem>
                </Link>

                <Link to="/inventory" className={classes.linkStyle} >
                  <ListItem button key="StyleIcon">
                    <ListItemIcon><img src="/assets/inventory-removebg-preview.png" style={{ width: '25px', height: '20px' }} alt="Sales" /></ListItemIcon>
                    <ListItemText primary={"Inventory Module"} />
                  </ListItem>
                </Link>




                <Link to="/accounts" className={classes.linkStyle} >
                  <ListItem button key="StyleIcon">
                    <ListItemIcon><img src="/assets/account-removebg-preview.png" style={{ width: '25px', height: '20px' }} alt="Sales" /></ListItemIcon>
                    <ListItemText primary={"Financial Accounts"} />
                  </ListItem>
                </Link>
                <Link to="/hrpayroll" className={classes.linkStyle} >
                  <ListItem button key="StyleIcon">
                    <ListItemIcon><img src="/assets/hr-removebg-preview-removebg-preview.png" style={{ width: '25px', height: '20px' }} alt="Sales" /></ListItemIcon>
                    <ListItemText primary={"HR & Payroll"} />
                  </ListItem>
                </Link>
                <Link to="/reports" className={classes.linkStyle} >
                  <ListItem button key="StyleIcon">
                    <ListItemIcon><img src="/assets/report-removebg-preview.png" style={{ width: '23px', height: '20px' }} alt="Sales" /></ListItemIcon>
                    <ListItemText primary={"Reports Module"} />
                  </ListItem>
                </Link>



                <Link to="/crm" className={classes.linkStyle} >
                  <ListItem button key="StyleIcon">
                    <ListItemIcon><img src="/assets/crm-removebg-preview.png" style={{ width: '23px', height: '20px' }} alt="CRM" /></ListItemIcon>
                    <ListItemText primary={"CRM Module"} />
                  </ListItem>
                </Link>



                <Link to="/settings" className={classes.linkStyle} >
                  <ListItem button key="StyleIcon">
                    <ListItemIcon><img src="/assets/settings-removebg-preview.png" style={{ width: '23px', height: '20px' }} alt="Setting" /></ListItemIcon>
                    <ListItemText primary={"Settings"} />
                  </ListItem>
                </Link>

              </Fragment>
            ) : <>

            </>
          }

          {
            authInfo.acc_type == 'customer' ? (
              <>
                <Link to="/sales/sales-order-entry-own" className={classes.linkStyle} >
                  <ListItem button key="StyleIcon">
                    <ListItemIcon><img src="/assets/report-removebg-preview.png" style={{ width: '29px', height: '20px' }} alt="Sales" /></ListItemIcon>
                    <ListItemText primary={"Order Now"} />
                  </ListItem>
                </Link>

                <Link to="/sales/sales-order-record-own" className={classes.linkStyle} >
                  <ListItem button key="StyleIcon">
                    <ListItemIcon><img src="/assets/report-removebg-preview.png" style={{ width: '29px', height: '20px' }} alt="Sales" /></ListItemIcon>
                    <ListItemText primary={"My Order Record"} />
                  </ListItem>
                </Link>
                <Link to="/sales/sales-record" className={classes.linkStyle} >
                  <ListItem button key="StyleIcon">
                    <ListItemIcon><img src="/assets/report-removebg-preview.png" style={{ width: '29px', height: '20px' }} alt="Sales" /></ListItemIcon>
                    <ListItemText primary={"My Purchase"} />
                  </ListItem>
                </Link>

                <Link to="/reports/debtor-ledger-own" className={classes.linkStyle} >
                  <ListItem button key="StyleIcon">
                    <ListItemIcon><img src="/assets/report-removebg-preview.png" style={{ width: '29px', height: '20px' }} alt="Sales" /></ListItemIcon>
                    <ListItemText primary={"My Ledger"} />
                  </ListItem>
                </Link>



                <p style={{ fontWeight: 'bold', textAlign: 'center', cursor: 'pointer', background: 'red' }} onClick={logout}>Logout</p>

              </>
            ) : ''
          }
          {
            currentRoute == 'stock' ? (
              <>








              </>
            ) : ''
          }
          {/* Sales's Menus */}
          {
            currentRoute == 'sales' ? (
              <Fragment>




              </Fragment>


            ) : ''
          }
          {
            currentRoute == 'accounts' ? (
              <Fragment>


                <Link to="/accounts" className={classes.linkStyle} >
                  <ListItem button key="HomeIcon">
                    <ListItemText className="module-text" primary={"Accounts Module"} />
                  </ListItem>
                </Link>




                {
                  accessChecker('debtor_receipt') > -1 ? (
                    <>
                      <Link to="/accounts/debitor-receipt-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Customer Receipt  Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('creditor_payment') > -1 ? (
                    <>
                      <Link to="/accounts/creditor-payment-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Supplier Payment  Entry"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }

                {
                  accessChecker('expense_entry') > -1 ? (
                    <>
                      <Link to="/accounts/expense-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Expense  Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('income_entry') > -1 ? (
                    <>
                      <Link to="/accounts/income-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Income  Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('expense_recognition_entry') > -1 ? (
                    <>
                      <Link to="/accounts/expense-recognition-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Expense Recognition Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }




                {
                  accessChecker('collection_entry_with_report') > -1 ? (
                    <>
                      <Link to="/accounts/collection-entry-with-report" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Collection Entry & Report"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('branch_tran') > -1 ? (
                    <>
                      <Link to="/accounts/branch-tran" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Branch Transaction  Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }



                {
                  accessChecker('contra_entry') > -1 ? (
                    <>
                      <Link to="/accounts/contra-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Contra / Single Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('advance_transaction_entry') > -1 ? (
                    <>
                      <Link to="/accounts/advance-tran-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Advance Transaction Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }






                {
                  accessChecker('journal_entry') > -1 ? (
                    <>
                      <Link to="/accounts/journal-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Journal / Double Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }



                {
                  accessChecker('collection_group_entry') > -1 ? (
                    <>
                      <Link to="/accounts/collection-group-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Collection Group Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('account_entry') > -1 ? (
                    <>
                      <Link to="/accounts/account-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Account  Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }






                {
                  accessChecker('location_entry') > -1 ? (
                    <>
                      <Link to="/accounts/location-manage" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Location Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }



              </Fragment>
            ) : ''
          }










          {
            currentRoute == 'manufacturing' ? (
              <>

                {
                  accessChecker('production_entry') > -1 ? (
                    <>
                      <Link to="/manufacturing/manufacturing-journal-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Manufacturing Journal Entry"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }

                {
                  accessChecker('production_voucher') > -1 ? (
                    <>
                      <Link to="/manufacturing/view-manufacturing-voucher" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Manufacturing Vouchers"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('production_record') > -1 ? (
                    <>
                      <Link to="/manufacturing/manufacturing-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Manufacturing Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }








              </>
            ) : ''
          }

          {
            currentRoute == 'service' ? (

              <Fragment>


                {
                  accessChecker('service_entry') > -1 ? (
                    <>
                      <Link to="/service/service-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Service Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('service_expense_entry') > -1 ? (
                    <>
                      <Link to="/service/service-expense-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Service Expense Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('service_voucher') > -1 ? (
                    <>
                      <Link to="/service/view-service-vouchers" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"View Service Vouchers"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }




                {
                  accessChecker('service_expense_voucher') > -1 ? (
                    <>
                      <Link to="/service/view-service-expense-vouchers" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Service Expense Vouchers"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('service_record') > -1 ? (
                    <>
                      <Link to="/service/service-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Service Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('service_expense_record') > -1 ? (
                    <>
                      <Link to="/service/service-expense-record" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Service Expense Record"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }



              </Fragment>

            ) : ''
          }

          {
            currentRoute == 'purchase' ? (

              <Fragment>


              </Fragment>

            ) : ''
          }
          {
            currentRoute == 'hrpayroll' ? (
              <Fragment>

                {
                  accessChecker('attendance_entry') > -1 ? (
                    <>
                      <Link to="/hrpayroll/attendance-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Attendance Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }



                {
                  accessChecker('salary_payment') > -1 ? (
                    <>
                      <Link to="/hrpayroll/salary-payment-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Salary Payment Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('employee_entry') > -1 ? (
                    <>
                      <Link to="/hrpayroll/employee-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Employee Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('department_entry') > -1 ? (
                    <>
                      <Link to="/hrpayroll/department-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Department Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('designation_entry') > -1 ? (
                    <>
                      <Link to="/hrpayroll/designation-entry" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Designation Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('salary_report') > -1 ? (
                    <>
                      <Link to="/hrpayroll/salary-report" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Salary Payment Report"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('monthly_salary_report') > -1 ? (
                    <>
                      <Link to="/hrpayroll/monthly-salary-report" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Monthly salary Report"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }



              </Fragment>
            ) : ''
          }
          {/* Administrator's Menus */}
          {
            currentRoute == 'settings' ? (
              <Fragment>

                {
                  accessChecker('item_entry') > -1 ? (
                    <>
                      <Link to="/settings/item-manage" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Item Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }

                {
                  accessChecker('item_unit') > -1 ? (
                    <>
                      <Link to="/settings/unit-manage" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Unit Entry & Measurement"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('group_entry') > -1 ? (
                    <>
                      <Link to="/settings/group-manage" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Group Entry"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }

                {
                  accessChecker('item_category') > -1 ? (
                    <>
                      <Link to="/settings/category-manage" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Category Entry"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }


                {
                  accessChecker('model_manage') > -1 ? (
                    <>
                      <Link to="/settings/model-manage" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Model Entry"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }


                {
                  accessChecker('origin_manage') > -1 ? (
                    <>
                      <Link to="/settings/origin-manage" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Origin Entry"} />
                        </ListItem>
                      </Link>

                    </>
                  ) : ''
                }

                {
                  accessChecker('warehouse_entry') > -1 ? (
                    <>
                      <Link to="/settings/warehouse-manage" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Warehouse Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }



                {
                  accessChecker('branch_entry') > -1 ? (
                    <>
                      <Link to="/settings/branch-manage" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Branch Entry"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('sms_sender') > -1 ? (
                    <>
                      <Link to="/settings/sms-sender" className={classes.linkStyle}>
                        <ListItem button key="PermDataSettingIcon">
                          <ListItemText primary={"Sms Sender"} />
                        </ListItem>
                      </Link>
                    </>
                  ) : ''
                }


                {
                  accessChecker('user_entry') > -1 || authInfo.role == 'super_admin' ? (

                    <Link to="/settings/user-manage" className={classes.linkStyle}>
                      <ListItem button key="PermDataSettingIcon">
                        <ListItemText primary={"User Entry"} />
                      </ListItem>
                    </Link>

                  ) : ''
                }

                {
                  accessChecker('company_profile') > -1 ? (
                    <Link to="/settings/institution-profile" className={classes.linkStyle}>
                      <ListItem button key="PermDataSettingIcon">
                        <ListItemText primary={"Company Profile"} />
                      </ListItem>
                    </Link>

                  ) : ''
                }


              </Fragment>
            ) : ''
          }


          {
            currentRoute == 'crm' ? (
              <Fragment>

                <Link to="/crm" className={classes.linkStyle} >
                  <ListItem button key="HomeIcon">
                    <ListItemText className="module-text" primary={"CRM Module"} />
                  </ListItem>
                </Link>


                {
                  accessChecker('customer_entry') > -1 ? (

                    <Link to="/crm/customer-entry" className={classes.linkStyle}>
                      <ListItem button key="PermDataSettingIcon">
                        <ListItemText primary={"Customer Entry"} />
                      </ListItem>
                    </Link>

                  ) : ''
                }

                {
                  accessChecker('pending_customer_list') > -1 ? (


                    <Link to="/crm/customer-pending-list" className={classes.linkStyle}>
                      <ListItem button key="PermDataSettingIcon">
                        <ListItemText primary={"Pending Customer List"} />
                      </ListItem>
                    </Link>

                  ) : ''
                }


                {
                  accessChecker('approved_customer_list') > -1 ? (
                    <Link to="/crm/customer-approved-list" className={classes.linkStyle}>
                      <ListItem button key="PermDataSettingIcon">
                        <ListItemText primary={" Approved Customer List"} />
                      </ListItem>
                    </Link>

                  ) : ''
                }


                {
                  accessChecker('rejected_customer_list') > -1 ? (
                    <Link to="/crm/customer-rejected-list" className={classes.linkStyle}>
                      <ListItem button key="PermDataSettingIcon">
                        <ListItemText primary={" Rejected Customer List"} />
                      </ListItem>
                    </Link>

                  ) : ''
                }

                {
                  accessChecker('employee_wise_customer_list') > -1 ? (
                    <Link to="/crm/user-wise-customer-report" className={classes.linkStyle}>
                      <ListItem button key="PermDataSettingIcon">
                        <ListItemText primary={" Employee Wise Customer Report"} />
                      </ListItem>
                    </Link>

                  ) : ''
                }

              </Fragment>
            ) : ''
          }

        </List>
      </Drawer>





      {/* Switch Branch  Modal */}
      <Modal
        open={branchSwitch}
        onClose={() => branchSwitchSet(false)}
        center
        style={{ minWidth: '300px', minHeight: '500px' }}


      >
        <Grid item xs={12} sm={12}  >

          <Autocomplete

            style={{ width: '100%', padding: '10px' }}
            options={branches}
            size="small"

            getOptionLabel={(option) => option.branch_name}

            value={selectedBranch}
            onChange={(event, selectedObj) => {
              selectedBranchSet(selectedObj)
            }}
            renderInput={(params) => (
              <TextField

                {...params}
                type="text"
                label="Switch a Branch"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  endAdornment: (
                    <React.Fragment>
                      {/* {areas.length==0 ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment} */}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />



          <Grid item xs={12} sm={12} style={{ marginTop: '6px', marginLeft: '10px' }} >
            <TextField type="text" className={classes.fullWidth}

              value={user_password}
              label="Enter your  password"
              style={{ color: '#222' }} variant="outlined" size="small" onChange={(e) => user_password_set(e.target.value)}

            />

          </Grid>




          <Button style={{ marginTop: '25px' }}
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            startIcon={<SaveIcon />}
            onClick={() => actionToSwitch()}
          >
            Switch To Branch
          </Button>




        </Grid>



      </Modal>
    </div>

  )
}
function mapStateToProps(state) {
  return {
    currentRoute: state.currentRouteReducer,
    authInfo: state.authInfoReducer
  }
}
export default connect(mapStateToProps, {
  createdBranchSet, updatedBranchSet, disableRestoreBranchSet,
  createdWarehouseSet, updatedWarehouseSet, disableRestoreWarehouseSet,

})(Header)