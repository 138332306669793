import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { currentRouteSet } from "../actions/actions";
import { pathSpliter } from "../lib/functions";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { APP_URL, API_URL } from "../config";
import "./global.css";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import { Typography } from "@material-ui/core";
import StoreMallDirectoryIcon from "@material-ui/icons/StoreMallDirectory";
import NoteIcon from "@material-ui/icons/Note";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import BuildIcon from "@material-ui/icons/Build";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ShopIcon from "@material-ui/icons/Shop";
import Chart from "react-google-charts";
import AnalogueClock from 'react-analogue-clock';
import DigitalClock from 'react-digital-clock';
import moment from 'moment';
import axios from 'axios';

import { currentDateTime, convertNumberToWords, dateTimeFormat, dateFormat, currentDateStartTime, currentDateEndTime } from '.././lib/functions'


import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')


const drawerWidth = 250;
const clockOptions = {
  baseColor: '#ffffff',
  borderColor: '#000000',
  borderWidth: 5,
  centerColor: '#000000',
  handColors: {
    hour: '#000000',
    minute: '#000000',
    second: '#000000',
  },
  notchColor: '#000000',
  numbersColor: '#000000',
  showNumbers: true,
  size: 250,
}


const useStyles = makeStyles((theme) => ({
  box: {
    color: '#e1f8fb',
    height: '177px',
    margin: '0px',
    background: '#23B7A7',
    textAlign: 'center',
    textDecoration: 'none',
    borderRadius: '20px',
    marginTop: '10px',
    width: '23%',
    margin: '1%'
  },

  boxTitle: {
    color: '#484848',
    fontWeight: 'bold',
    fontSize: '18px',
    margin: '0px'
  },
  '@global': {
    '.MuiBox-root': {
      paddingBottom: '0px !important',
      paddingTop: '20px'
    },
    '.MuiBox-root p': {
      color: 'white'
    },
    '.MuiBox-root svg': {
      color: 'white',
      fontWidth: 'bold'
    }
  }
}));

const logout = () => {
  sessionStorage.clear();
  window.location.href = `${APP_URL}`
}

const Dashboard = ({ location, currentRoute, currentRouteSet, authInfo }) => {
  useEffect(() => {
    currentRouteSet(pathSpliter(location.pathname, 1));
    getDailyBalances()
    getBalances()

    getLastSevenDaysSalesExpense()
    getExpenses()
    getTopSoldItems()



  }, []);

  let [amount, amountSet] = useState(10000)


  let [today_cash, today_cash_set] = useState(0)
  let [today_bank_balance, today_bank_balance_set] = useState(0)
  let [today_expense, today_expense_set] = useState(0)
  let [today_income, today_income_set] = useState(0)
  let [today_sale, today_sale_set] = useState(0)
  let [today_profit_loss, today_profit_loss_set] = useState(0)

  let [bank_balance, bank_balance_set] = useState(0)
  let [cash_in_hand, cash_in_hand_set] = useState(0)
  let [creditor_balance, creditor_balance_set] = useState(0)
  let [debtor_balance, debtor_balance_set] = useState(0)



  let [expenses, expensesSet] = useState([])
  let [items, itemsSet] = useState([])

  let [fromDate, fromDateSet] = useState(currentDateStartTime())
  let [toDate, toDateSet] = useState(currentDateEndTime())


  let [dateOfLastSeven, dateOfLastSevenSet] = useState(moment().subtract(6, 'd').toISOString())
  let [dateOfLastSix, dateOfLastSixSet] = useState(moment().subtract(5, 'd').toISOString())
  let [dateOfLastFive, dateOfLastFiveSet] = useState(moment().subtract(4, 'd').toISOString())
  let [dateOfLastFour, dateOfLastFourSet] = useState(moment().subtract(3, 'd').toISOString())
  let [dateOfLastThree, dateOfLastThreeSet] = useState(moment().subtract(2, 'd').toISOString())
  let [dateOfLastTwo, dateOfLastTwoSet] = useState(moment().subtract(1, 'd').toISOString())
  let [dateOfLastOne, dateOfLastOneSet] = useState(moment().subtract(0, 'd').toISOString())


  let [lastOneSold, lastOneSoldSet] = useState(0)
  let [lastTwoSold, lastTwoSoldSet] = useState(0)
  let [lastThreeSold, lastThreeSoldSet] = useState(0)
  let [lastFourSold, lastFourSoldSet] = useState(0)
  let [lastFiveSold, lastFiveSoldSet] = useState(0)
  let [lastSixSold, lastSixSoldSet] = useState(0)
  let [lastSevenSold, lastSevenSoldSet] = useState(0)

  let [lastOneExpense, lastOneExpenseSet] = useState(0)
  let [lastTwoExpense, lastTwoExpenseSet] = useState(0)
  let [lastThreeExpense, lastThreeExpenseSet] = useState(0)
  let [lastFourExpense, lastFourExpenseSet] = useState(0)
  let [lastFiveExpense, lastFiveExpenseSet] = useState(0)
  let [lastSixExpense, lastSixExpenseSet] = useState(0)
  let [lastSevenExpense, lastSevenExpenseSet] = useState(0)

  const getExpenses = async () => {
    await axios.post(`${API_URL}/api/get-indirect-expense-balance`, {
      fromDate: moment(dateOfLastSeven).startOf('day').toISOString()
      , toDate: moment(dateOfLastOne).endOf('day').toISOString()
    }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      //expensesSet(res.data.expenses)
      let temExpense = []
      res.data.expenses.map((exp) => {

        temExpense.push([exp.acc_name, exp.balance])
      });
      expensesSet([...temExpense])



    })
  }

  const getTopSoldItems = async () => {
    await axios.post(`${API_URL}/api/get-detail-stock`, {
      fromDate: moment(dateOfLastSeven).startOf('day').toISOString()
      , toDate: moment(dateOfLastOne).endOf('day').toISOString()
    }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      itemsSet(res.data)
      let topItems = []
      res.data.map((item) => {

        topItems.push([item.item_name, item.sale_qty])
      });
      itemsSet([...topItems])

    })
  }

  const getLastSevenDaysSalesExpense = async () => {
    // sales
    await axios.post(`${API_URL}/api/get-sales-balance`, {
      fromDate: moment(dateOfLastOne).startOf('day').toISOString()
      , toDate: moment(dateOfLastOne).endOf('day').toISOString(), type: "head_total"
    }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      lastOneSoldSet(res.data.total_balance)
    })
    await axios.post(`${API_URL}/api/get-sales-balance`, {
      fromDate: moment(dateOfLastTwo).startOf('day').toISOString()
      , toDate: moment(dateOfLastTwo).endOf('day').toISOString(), type: "head_total"
    }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      lastTwoSoldSet(res.data.total_balance)
    })
    await axios.post(`${API_URL}/api/get-sales-balance`, {
      fromDate: moment(dateOfLastThree).startOf('day').toISOString()
      , toDate: moment(dateOfLastThree).endOf('day').toISOString(), type: "head_total"
    }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      lastThreeSoldSet(res.data.total_balance)
    })
    await axios.post(`${API_URL}/api/get-sales-balance`, {
      fromDate: moment(dateOfLastFour).startOf('day').toISOString()
      , toDate: moment(dateOfLastFour).endOf('day').toISOString(), type: "head_total"
    }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      lastFourSoldSet(res.data.total_balance)
    })
    await axios.post(`${API_URL}/api/get-sales-balance`, {
      fromDate: moment(dateOfLastFive).startOf('day').toISOString()
      , toDate: moment(dateOfLastFive).endOf('day').toISOString(), type: "head_total"
    }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      lastFiveSoldSet(res.data.total_balance)
    })
    await axios.post(`${API_URL}/api/get-sales-balance`, {
      fromDate: moment(dateOfLastSix).startOf('day').toISOString()
      , toDate: moment(dateOfLastSix).endOf('day').toISOString(), type: "head_total"
    }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      lastSixSoldSet(res.data.total_balance)
    })
    await axios.post(`${API_URL}/api/get-sales-balance`, {
      fromDate: moment(dateOfLastSeven).startOf('day').toISOString()
      , toDate: moment(dateOfLastSeven).endOf('day').toISOString(), type: "head_total"
    }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      lastSevenSoldSet(res.data.total_balance)
    })
    // Expense 
    await axios.post(`${API_URL}/api/get-indirect-expense-balance`, {
      fromDate: moment(dateOfLastOne).startOf('day').toISOString()
      , toDate: moment(dateOfLastOne).endOf('day').toISOString(), type: "head_total"
    }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      lastOneExpenseSet(res.data.total_balance)
    })

    await axios.post(`${API_URL}/api/get-indirect-expense-balance`, {
      fromDate: moment(dateOfLastTwo).startOf('day').toISOString()
      , toDate: moment(dateOfLastTwo).endOf('day').toISOString(), type: "head_total"
    }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      lastTwoExpenseSet(res.data.total_balance)
    })

    await axios.post(`${API_URL}/api/get-indirect-expense-balance`, {
      fromDate: moment(dateOfLastThree).startOf('day').toISOString()
      , toDate: moment(dateOfLastThree).endOf('day').toISOString(), type: "head_total"
    }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      lastThreeExpenseSet(res.data.total_balance)
    })

    await axios.post(`${API_URL}/api/get-indirect-expense-balance`, {
      fromDate: moment(dateOfLastFour).startOf('day').toISOString()
      , toDate: moment(dateOfLastFour).endOf('day').toISOString(), type: "head_total"
    }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      lastFourExpenseSet(res.data.total_balance)
    })

    await axios.post(`${API_URL}/api/get-indirect-expense-balance`, {
      fromDate: moment(dateOfLastFive).startOf('day').toISOString()
      , toDate: moment(dateOfLastFive).endOf('day').toISOString(), type: "head_total"
    }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      lastFiveExpenseSet(res.data.total_balance)
    })
    await axios.post(`${API_URL}/api/get-indirect-expense-balance`, {
      fromDate: moment(dateOfLastSix).startOf('day').toISOString()
      , toDate: moment(dateOfLastSix).endOf('day').toISOString(), type: "head_total"
    }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      lastSixExpenseSet(res.data.total_balance)
    })
    await axios.post(`${API_URL}/api/get-indirect-expense-balance`, {
      fromDate: moment(dateOfLastSeven).startOf('day').toISOString()
      , toDate: moment(dateOfLastSeven).endOf('day').toISOString(), type: "head_total"
    }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      lastSevenExpenseSet(res.data.total_balance)
    })

  }


  const getBalances = async () => {
    await axios.post(`${API_URL}/api/get-accounts-balance`, { "accType": "'cash_in_hand'", type: "head_total" }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      cash_in_hand_set(res.data.total_balance)
    })

    await axios.post(`${API_URL}/api/get-accounts-balance`, { "accType": "'bank_account'", type: "head_total" }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      bank_balance_set(res.data.total_balance)
    })
    await axios.post(`${API_URL}/api/get-sundry-creditor-balance`, { type: "head_total", accType: "'creditor'" }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      creditor_balance_set(res.data.total_balance)
    })
    await axios.post(`${API_URL}/api/get-sundry-debitor-balance`, { type: "head_total", accType: "'debitor'" }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      debtor_balance_set(res.data.total_balance)
    })

  }


  const getDailyBalances = async () => {
    await axios.post(`${API_URL}/api/get-debitor-rcv-record`, { fromDate, toDate }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      today_cash_set(res.data.reduce((prev, curr) => {
        return prev + parseFloat(curr.rcv_total)
      }, 0))
    })



    await axios.post(`${API_URL}/api/get-indirect-expense-balance`, { fromDate, toDate, type: "head_total" }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      today_expense_set(res.data.total_balance)
    })

    await axios.post(`${API_URL}/api/get-indirect-income-balance`, { fromDate, toDate, type: "head_total" }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      today_income_set(res.data.total_balance)
    })

    await axios.post(`${API_URL}/api/get-sales-balance`, { fromDate, toDate, type: "head_total" }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      today_sale_set(res.data.total_balance)
    })

    await axios.post(`${API_URL}/api/get-profit-loss-balance`, { fromDate, toDate, type: "head_total" }, { headers: { 'auth-token': authInfo.token } }).then(res => {
      today_profit_loss_set(res.data.profitBalance)
    })

  }




  const classes = useStyles();
  return (
    <>

      {
        authInfo.acc_type != 'customer' ? (

          <>

            


            {
              authInfo.role == 'super_admin' ? (<>
                <Grid container>





                  <Grid item xs={12} sm={4}>
                    <Chart
                      width={'100%'}
                      height={'auto'}
                      chartType="Table"
                      loader={<div>Loading Chart</div>}
                      data={[
                        [
                          { type: 'string', label: 'Particular Accounts' },
                          { type: 'number', label: 'Account Balance' },
                        ],
                        ['Today Due Collection', { v: today_cash, f: `${format(parseFloat(today_cash).toFixed(2))} ${authInfo.currency}` }],
                        ['Today Sales', { v: today_sale, f: `${format(parseFloat(today_sale).toFixed(2))} ${authInfo.currency}` }],
                        ['Today Expense', { v: today_expense, f: `${format(parseFloat(today_expense).toFixed(2))} ${authInfo.currency}` }],
                        ['Today Profit/ Loss', { v: today_profit_loss, f: `${format(parseFloat(today_profit_loss).toFixed(2))} ${authInfo.currency}` }],
                      ]}
                      options={{
                        showRowNumber: true,
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </Grid>


                  <Grid item xs={12} sm={4}>
                    <Chart
                      width={'100%'}
                      height={'auto'}
                      chartType="Table"
                      loader={<div>Loading Chart</div>}
                      data={[
                        [
                          { type: 'string', label: 'Particular Accounts' },
                          { type: 'number', label: 'Account Balance' },
                        ],
                        ['Cash Balance', { v: cash_in_hand, f: ` ${format(parseFloat(cash_in_hand).toFixed(2))} ${authInfo.currency}` }],
                        ['Bank Balance', { v: bank_balance, f: ` ${format(parseFloat(bank_balance).toFixed(2))} ${authInfo.currency}` }],
                        ['Customer  Due ', { v: debtor_balance, f: `${format(parseFloat(debtor_balance).toFixed(2))} ${authInfo.currency}` }],
                        ['Supplier  Due', { v: creditor_balance, f: ` ${format(parseFloat(creditor_balance).toFixed(2))} ${authInfo.currency}` }],
                      ]}
                      options={{
                        showRowNumber: true,
                      }}
                      rootProps={{ 'data-testid': '1' }}
                    />

                    <p className="digital-watch" style={{ color: 'green', float: 'right' }}><DigitalClock /></p>
                    <p className="date-text">{moment().format(dateFormat)} </p>

                  </Grid>


                  <Grid item xs={12} sm={4} style={{ marginTop: '-20px' }}>
                    <AnalogueClock {...clockOptions} />

                  </Grid>

                </Grid>

              </>) : ''
            }





            {/* Best Item Sold */}

            {
              authInfo.role == 'super_admin' ? (<>

                <Grid container>
                  <Grid item xs={12} sm={9} className="shortcut-links">

                    <h6>Shortcut Links</h6>


                    <li><Link to="/sales/sales-entry">Sales Entry</Link></li>
                    <li><Link to="/accounts/account-entry">Account Entry</Link></li>

                    <li><Link to="/accounts/debitor-receipt-entry">Customer Receipt Entry</Link></li>
                    <li><Link to="/accounts/creditor-payment-entry">Supplier Payment Entry</Link></li>
                    <li><Link to="/accounts/journal-entry">Journal Entry</Link></li>
                    <li><Link to="/accounts/expense-entry">Expense Entry</Link></li>
                    <li><Link to="/accounts/income-entry">Income Entry</Link></li>

                    <li><Link to="/purchase/purchase-entry">Purchase Entry</Link></li>
                    <li><Link to="/settings/item-manage">Item Entry</Link></li>
                    <li><Link to="/manufacturing/manufacturing-journal-entry">Manufacturing Journal Entry</Link></li>
                    <li><Link to="/reports/balance-sheet">Balance Sheet</Link></li>
                    <li><Link to="/reports/account-balance">Cash & Bank Balance</Link></li>
                    <li><Link to="/reports/trial-balance">Trial Balance</Link></li>
                    <li><Link to="/reports/debitors-balance">Customer Balance</Link></li>
                    <li><Link to="/reports/item-stock-report">Stock Report</Link></li>
                  </Grid>



                  {/* <Grid item xs={12} sm={3}>

      </Grid> */}
                </Grid>

                <Grid container>


                  <Grid item xs={12} sm={5}>
                    <Chart
                      width={'500px'}
                      height={'300px'}
                      chartType="AreaChart"
                      loader={<div>Loading Chart</div>}
                      data={[
                        ['Day', 'Sales', 'Expenses'],
                        ['1', lastOneSold, lastOneExpense],
                        ['2', lastTwoSold, lastTwoExpense],
                        ['3', lastThreeSold, lastThreeExpense],
                        ['4', lastFourSold, lastFourExpense],
                        ['5', lastFiveSold, lastFiveExpense],
                        ['6', lastSixSold, lastSixExpense],
                        ['7', lastSevenSold, lastSevenExpense],
                      ]}
                      options={{
                        title: 'Company performance last 7 days',
                        hAxis: { title: 'Day', titleTextStyle: { color: '#333' } },
                        vAxis: { minValue: 0 },
                        // For the legend to fit, we make the chart area smaller
                        chartArea: { width: '50%', height: '70%' },
                        // lineWidth: 25
                      }}
                      // For tests
                      rootProps={{ 'data-testid': '1' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Chart
                      width={'100%'}
                      height={'300px'}
                      chartType="PieChart"
                      loader={<div>Loading Chart</div>}
                      data={[
                        ['Expense Head', 'Amount'],
                        ...expenses

                      ]}
                      options={{
                        title: ' Expense last 7 days ',
                        // Just add this option
                        is3D: true,
                      }}
                      rootProps={{ 'data-testid': '2' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Chart
                      width={'500px'}
                      height={'300px'}
                      chartType="PieChart"
                      loader={<div>Loading Chart</div>}
                      data={[
                        ['Product', 'Popularity'],
                        ...items
                      ]}
                      options={{
                        title: 'Top sold products last 7 days',
                        sliceVisibilityThreshold: 0.2, // 20%
                      }}
                      rootProps={{ 'data-testid': '7' }}
                    />
                  </Grid>

                </Grid>

              </>) : ''
            }


          </>

        ) : <>

          <p style={{ fontStyle: 'italic', fontSize: '16px', fontWeight: 'bold' }}>Dealer Account Name : {
            authInfo.userInfo.user_name
          }</p>

        </>
      }


    </>
  )
}
const mapStateToProps = (state) => {
  return {
    currentRoute: state.returnReducer,
    authInfo: state.authInfoReducer
  }
}
export default connect(mapStateToProps, { currentRouteSet })(Dashboard)